<template>
  <div class="home" fluid>
      <v-row>
        <v-col md="2" lg="2" offset style="margin-top:0.9em;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 0.1em honeydew">
            <img src="../assets/ihx-logo.png" alt="" width="88" height="82%">
        </v-col>
        <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
            <!-- <div style="font-size:2em;font-weight: bold;margin-top:1.2%;padding-left:5%;padding-top:0.5%;">IHX Claims Platform</div> -->
            <!-- <v-avatar color="blue"> -->
            <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
              <v-icon dark large style="padding-right:5%;">
                mdi-account-circle
              </v-icon>
              <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
              <div class="drp-dwn" v-show="showDropdown">
                <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
              </div>

            </div>
        </v-col>
        
      </v-row>

      <v-row style="margin-bottom:4.25em">
        <v-col md="5" lg="5" class="wel-col">
          <div class="wel-to" style="margin-top: 2.5em;color:#4240c6;">Welcome to </div><br>
          <div class="wel-to-ihx" style="color:#4240c6;"><b> <span style="color:#4240c6;opacity:0.9;">IHX</span> Demo Platform</b></div>

          <div  style="margin-top:1.17em">
            <!-- <div id="vis-head">Vision</div> -->

            <div id="vis-data">IHX is India’s largest health information exchange platform, infused with AI/ML capabilities, delivering solutions to stakeholders in all segments of the healthcare ecosystem.</div>

            <!-- <v-btn color="#2E2BEB" disabled class="ma-2 white--text" type="submit" style="font-family: Open Sans;font-weight:bolder;width:202px;height:2.8em;font-size:20px;line-height:26px;text-transform:none;">
              Play Demo
            <img src="../assets/play_icon.svg" alt="" style="padding-left:10%;"></v-btn> -->

          </div>
        </v-col>
        <v-col md="6" lg="6" style="margin-top:8em;">
          <v-img src="../assets/main_page.svg" width="678px"></v-img>
        </v-col>
      </v-row>

      <hr style="border:0.04em dashed #B6B6B6;left:7.17%;right:7.1%;position:absolute;">

      <v-row style="margin-left:9.6rem;margin-right:8.5rem;margin-top:8em;margin-bottom:0em;">
        <v-col md="12" lg="12" style="margin-bottom:2.5em;">
          <div id="pdt-head" style="margin-left:-61px;">Products</div>
        </v-col>

        <!-- <hr style="border:0.04em dashed #B6B6B6;left:20%;right:20%;position:absolute;z-index:100;margin-top:14rem;"> -->

        <v-col md="6" lg="6">
          <v-card class="mx-auto"
            hover height="15rem"
            outlined @click="showPdt(5)" style="border: 0.7px solid #B0B0B0;z-index:101;"
            > 
            <v-row style="padding:6%;">
              <v-col md="3" lg="3">
                <v-avatar size="70" color="#E4E4F1">
                  <span class="white--text text-h5">
                    <v-img src="../assets/provider_icon.svg" width="50px" style="margin-top:15%;"></v-img>
                  </span>
                </v-avatar>
              </v-col>
              <v-col md="9" lg="9">
                <b id="provider-head">Credit Management Suite</b>
                <div id="provider-text">The platform enables seamless interaction between the hospitals and payers across all the stages of an insurance claim. It provides a single platform for hospitals to manage their entire credit business.  The platform APIs are tightly integrated with payer systems to enable efficient claims processing.</div>
              </v-col>
            </v-row>
          </v-card>
          <hr style="border:0.04em dashed #B6B6B6;left:15rem;right:15rem;position:absolute;margin-top:-7rem;z-index:1;">

          <div>
            <hr style="border:0.04em dashed #B6B6B6;transform:rotate(90deg);width:5.5rem;margin-left:14rem;margin-top:0rem;line-height:0px;">
          </div>
        </v-col>

        <v-col md="6" lg="6" style="z-index:101;">
          <v-card class="mx-auto"
            hover height="15rem"
            outlined @click="showPdt(6)" style="border: 0.7px solid #B0B0B0;z-index:101;"
            > 
            <v-row style="padding:6%;">
              <v-col md="3" lg="3">
                <v-avatar size="70" color="#E4E4F1">
                  <span class="white--text text-h5">
                    <v-img src="../assets/data-analytics.svg" width="50px" style="margin-top:15%;"></v-img>
                  </span>
                </v-avatar>
              </v-col>
              <v-col md="9" lg="9">
                <b id="provider-head">Business Insights</b>
                <div id="provider-text">The analytical solutions provide business insights related to operational, financial, and other metrics to the stakeholders in the ecosystem. Such insights enable 360 degree view of their businesses to the stakeholders and empower them to take important business decisions.</div>
              </v-col>
            </v-row>
          </v-card>

          <hr style="border:0.04em dashed #B6B6B6;transform:rotate(90deg);width:5.5rem;margin-left:10rem;margin-top:0rem;line-height:0px;">

        </v-col>

        <!-- <div class="vertical-line" style="height:3em;z-index:100;margin-left:16rem;margin-top:-0.8rem;"></div> -->
        <!-- <div class="vertical-line" style="height:3.2em;z-index:100;margin-left:48rem;margin-top:-3.1rem;"></div> -->

        <!-- <hr style="border:0.04em dashed #B6B6B6;left:16.8rem;right:15.5rem;position:absolute;margin-top:24.5rem;z-index:10000;"> -->
        
        <!-- <v-col lg="3" md="3" v-for="id in [1,2,3,4]" :key="id">
          <hr :class="(id%2==0)?'steps':'small-steps'" style="border:0.04em dashed #B6B6B6;transform:rotate(90deg);margin-top:6.5rem;line-height:0px;">
        </v-col> -->

      </v-row>

      <hr id="mid-hr-line" style="border:0.04em dashed #B6B6B6;left:17.5rem;right:15.8rem;position:absolute;margin-top:2rem;z-index:10000;">

      <v-row style="margin-left:144px;margin-right:122px;margin-top:3em;margin-bottom:5em;">

        <v-col md="12" lg="12">
          <v-row style="margin:0px;">
            <v-col md="3" lg="3" style="text-align:center;align-self:center;"
              v-for="data in this.pdt_data" :key="data.id"
            >
              <hr :id="'vertical-line-'+data.id" :class="'hr-class-long'">

              <v-card class="mx-auto" :id="'colID'+data.id" :height="'200'"
                max-width="550" :hover="data.hov" :style="!(data.hov)?'cursor:default;border: 0.7px solid rgba(176, 176, 176, 0.27);':'border: 0.7px solid #B0B0B0;'"
                outlined @click="showPdt(data.id)"
              >

              <v-row style="padding:11%;">
                <v-col md="12" lg="12">
                  <v-avatar size="85" color="#E4E4F1">
                    <span class="white--text text-h5">
                      <v-img :src="data.img_path" width="40px" style="margin-top:20%;margin-left:5%;"></v-img>
                    </span>
                  </v-avatar>
                </v-col>
                <v-col md="12" lg="12">
                  <b id="provider-head" v-html="data.header">  </b> <br>
                  <!-- <div id="rest-text" v-html="data.text"></div> -->
                </v-col>
              </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Adding for New Blocks Here -->
        <v-col md="12" lg="12" style="margin-top:-0.5em;">
          <v-row style="margin:0px;">
            <v-col md="3" lg="3" style="text-align:center;align-self:center;"
              v-for="data in this.new_pdt_data" :key="data.id"
            >
              <hr :id="'vertical-line-'+data.id" :class="'hr-class-long'" v-show="data.show">

              <v-card class="mx-auto" :id="'colID'+data.id" :height="'200'" v-show="data.show"
                max-width="550" :hover="data.hov" :style="!(data.hov)?'cursor:default;border: 0.7px solid rgba(176, 176, 176, 0.27);':'border: 0.7px solid #B0B0B0;'"
                outlined @click="showPdt(data.id)"
              >

              <v-row style="padding:11%;">
                <v-col md="12" lg="12">
                  <v-avatar size="85" color="#E4E4F1">
                    <span class="white--text text-h5">
                      <v-img :src="data.img_path" width="40px" style="margin-top:20%;margin-left:5%;"></v-img>
                    </span>
                  </v-avatar>
                </v-col>
                <v-col md="12" lg="12">
                  <b id="provider-head" v-html="data.header">  </b> <br>
                  <!-- <div id="rest-text" v-html="data.text"></div> -->
                </v-col>
              </v-row>

              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- Ending Here for New Blocks -->
      </v-row>
      

      <v-footer dark padless style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          
          </v-row>
      </v-footer> 

  </div>
</template>

<script>

// const encryptFunc = require('../js/helpers');
// import apiHelpers from '../js/apiCalls'
// import FlowChart from '../components/FlowChart.vue'
// import { LinePath } from 'svg-dom-arrows';

export default {
  name: 'Home',
  components: {
    // FlowChart
  },
  
  created(){
    document.title = "IHX Demo App"

    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  mounted(){
    document.addEventListener('click',this.changeState);
    this.calcLinePosition();
  },
  methods:{
    changeState(e){
      let logoutButtonArea = this.$refs.logoutButton;
      
      if (!logoutButtonArea.contains(e.target)) {
        this.showDropdown = false
      }
    },
    calcLinePosition(){
      let hrLineWidth = document.getElementById('vertical-line-4').getBoundingClientRect().x - document.getElementById('vertical-line-1').getBoundingClientRect().x
      document.getElementById('mid-hr-line').style.width = hrLineWidth+'px';
    },
    doLogout(){
      this.$store.commit('resetState');
      this.$router.push('/').catch((e)=>{console.log(e);});
    },
    showPdt(id){
      
      if(this.routerData[id]['routing'] != 'none')
      {
        if(this.routerData[id]['routing'] == 'internal'){
          this.$router.push(this.routerData[id]['link']);
        }
        else{
          window.open(this.routerData[id]['link']);
        }
      }

    }
      },
  data(){
      return{
        showDropdown: false,
        usernameInfo:this.$store.state.username,
        routerData : {1:{'routing':'internal','link':'/docxClassifier'},
                      2:{'routing':'external','link':'https://ai-demo.ihx.in/services/bill/portal/login'},
                      3:{'routing':'external','link':'https://ai-demo.ihx.in/services/dsd/portal/login'},
                      4:{'routing':'external','link':'https://ai-demo.ihx.in/services/lrd/portal/login'},
                      5:{'routing':'external','link':'https://demo-provider.ihx.in/'},
                      6:{'routing':'internal','link':'/insights'},
                      7:{'routing':'internal','link':'/drugPrice'},
                      8:{'routing':'external','link':'https://ai-staging.ihx.in/services/tariff/portal/login'},
                      9:{'routing':'internal','link':'/PII'},
                      10:{'routing':'external','link':'https://ai-demo.ihx.in/services/watch-star/portal'},
                      },
        new_pdt_data : [{ "id" : 7,
                          "header" : "Drug Pricing Comparator",
                          "text" : "",
                          "img_path" : require("../assets/medical_icon.png"),
                          "hov" : true,
                          "show" : true},
                        { "id" : 8,
                          "header" : "Tariff Compliance",
                          "text" : "",
                          "img_path" : require("../assets/docx_icon.png"),
                          "hov" : true,
                          "show" : true,
                        },
                        { "id" : 9,
                          "header" : "PII Anonymization",
                          "text" : "",
                          "img_path" : require("../assets/lab_icon.png"),
                          "hov" : true,
                          "show" : true,
                        },
                        { "id" : 10,
                          "header" : "Status Tracker",
                          "text" : "",
                          "img_path" : require("../assets/bill_icon.png"),
                          "hov" : true,
                          "show" : true,
                        }],
        pdt_data : [{"id" : 1,
                     "header" : "Document Identifier/Indexer",
                     "text" : "Recognizes, indexes and extracts all pages for any documents uploaded during claims management process",
                     "img_path" : require("../assets/docx_icon.png"),
                     "hov" : true
                    },
                    {"id" : 2,
                     "header" : "Bill Digitizer",
                     "text" : "Digitizes all incoming scanned bills using multi class ML algorithm and converts them into desired output formats with high accuracy",
                     "img_path" : require("../assets/bill_icon.png"),
                     "hov" : true
                    },
                    {"id" : 3,
                     "header" : "Discharge Summary Digitizer",
                     "text" : "Extracts medical entities such as conditions, procedures, and diagnosis from discharge summaries using proprietary algorithms",
                     "img_path" : require("../assets/medical_icon.png"),
                     "hov" : true
                    },
                    {"id" : 4,
                     "header" : "Lab Digitizer",
                     "text" : "Identifies different types of lab reports and extracts parameter level information",
                     "img_path" : require("../assets/lab_icon.png"),
                     "hov" : true,
                    },]
      }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
  .home{
    /* font-family: 'Josefin Sans', sans-serif; */
  }
 .buttons:hover{
  background-color: #86acb8;
  /* color:white; */
}
/*
.mx-auto{
  background-color:firebrick
} */
.buttons{
  padding:15%;
  margin-top:2%;
  text-align:center;
  font-size:1.3em;
  /* background-color:#2ABF86; */
  /* color:white; */
  font-weight:bold;
}
div.vertical-line{
  border: 0.04em dashed #C6C6F3;
}

#svg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hr-class-long{
  border:0.04em dashed #B6B6B6;
  transform:rotate(90deg);
  width:5.1rem;
  margin-left:4.4rem;
  margin-top:0rem;
  line-height:0px;
  position: absolute;
}
.hr-class-small{
  border:0.04em dashed #B6B6B6;
  transform:rotate(90deg);
  width:6rem;
  margin-left:4.2rem;
  margin-top:0rem;
  line-height:0px;
}
#line{
  stroke-width:2px;
  stroke:rgb(0,0,0);
}

.class-green{
  background-color: #023d06;
  color: white;
}
.hr-class-small:first-child::before{
    content: '▶︎';
    position: relative;
    left:-0.25em;
    color: #B6B6B6;
    padding:0;
}
.hr-class-long:first-child::before{
    content: '▶︎';
    position: relative;
    left:-0.3em;
    color: #B6B6B6;
    padding:0;
}
.class-red{
  background-color: #8f2828;
  color: white;
}
.class-black{
  color: black;
}
.wel-col{
  margin-left:83px;
}
.wel-to{
    width: 287px;
    left: 83px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: .5em;
}
.wel-to-ihx{
  color:#4F595E;
  width: auto;
  left: 83px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: .5em;
  margin-bottom: 2em;
}
#vis-head{
  color: #4946C5;
  width: 103px;
  height: 1em;
  left: 83px;
  top: 342px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 0.5em;
  margin-bottom: 0.1em;
}

#provider-head{
  width: 272px;
  height: 1.2em;
  left: 219px;
  top: 864px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1em;
  /* identical to box height, or 110% */

  /* text-transform: uppercase; */

  color: #4946C5;
  /* margin-bottom:13px; */
}

#vis-data{
  /* position: absolute; */
width: 464px;
height: 8em;
left: 83px;
top: 382px;

font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 1.5em;
margin-bottom: 1em;
color: #000000;
}
#pdt-head{
  width: 125px;
  height: 1em;
  left: 83px;
  top: 756px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 1em;
  /* identical to box height, or 79% */
  color: #6F6F75;
}

#provider-text{
  width: auto;
  /* height: 200px; */
  left: 219px;
  top: 899px;
  margin-top:13px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6em;

  color: #000000;
}

#rest-text{
  width: auto;
  /* height: 200px; */
  left: 219px;
  top: 899px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5em;
  margin-top:13px;
  color: #000000;
}

.foot-class{
    padding-bottom:0;
    padding:1%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
  }
  #user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
  }
  .drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
  }
</style>
