<template>
  <v-container style="margin-top:1%;">
    
    <v-card class="mx-auto"
            max-width="500" hover
            elevation="3"
            outlined style="padding:2%;"
    >
    <v-simple-table fixed-header class="simple-table">
        <template v-slot:default>
        <thead class="t-heads">
            <tr>
                <th class="text-center" id="t-heads" v-for="header in dataHeaders" :key="header.value">
                    {{header.label}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
              v-for="item in rowData"
              :key="item.name"
              class="text-center"
              >
              <td>{{ item.label }}</td>
              <td>{{item.value}}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
    </v-card><br>
    <v-card class="mx-auto"
            max-width="500" hover
            elevation="3"
            outlined style="padding:2%;"
    >
    <v-simple-table fixed-header class="simple-table-2">
        <template v-slot:default>
        <thead class="t-heads">
            <tr>
                <th class="text-center" id="t-heads" v-for="header in secondTableHeader" :key="header.value">
                    {{header.label}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
              v-for="item in secondRowData"
              :key="item.pageno"
              class="text-center"
              @click = "scrollToPage(item.pageno)"
              >
              <td>{{ item.category }}</td>
              <td>{{item.pageno}}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
    >
      {{snackText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  const encryptFunc = require('../js/helpers');
  import {bus} from '../main'
  
  export default {
    name: 'SimpleTable',
    components:{
    },
    methods:{
        buttonClick(btnID){
          console.log(btnID);
          if(btnID==2){
            this.$router.push('/detail');
          }
          else{
            this.snackbar = true;
            this.snackText = 'Not available at the moment. Please check again later.';
          }
        },
        scrollToPage(data){
            // console.log(data);
            bus.$emit('change-page',data);
            // console.log(data);
        },        
        goHome(){
          this.$router.push('/');
        }
    },
    async created(){     
      const decrypt_pageData = encryptFunc.helpers.decryptJSON(this.$store.state.pagePerData);
      let finalData = decrypt_pageData.data.data;

      let firstTableData = finalData.summary;
      let keyHere = Object.keys(finalData.details)[0];    
      let secondTableData = finalData.details[`${keyHere}`];

      this.rowData = [{"sno":1,"label":"Bill Pages","value":firstTableData.num_bill_pages},
                      {"sno":2,"label":"Discharge Summary Pages","value":firstTableData.num_discharge_summary_pages},
                      {"sno":3,"label":"KYC Pages","value":firstTableData.num_kyc_pages},
                      {"sno":4,"label":"Lab Report Pages","value":firstTableData.num_lab_report_pages},
                      {"sno":5,"label":"NHI Pages","value":firstTableData.num_nhi_pages},
                      {"sno":6,"label":"Other Pages","value":firstTableData.num_others},
                      {"sno":7,"label":"Radiology Pages","value":firstTableData.num_radiology_report_pages},
                      {"sno":8,"label":"PAN Pages","value":firstTableData.num_pan_pages},
                      {"sno":9,"label":"Aadhar Pages","value":firstTableData.num_aadhar_pages},
                      {"sno":10,"label":"Aadhar PAN Pages","value":firstTableData.num_aadhar_pan_pages},
                      {"sno":11,"label":"Claim Form Pages","value":firstTableData.num_claim_form_pages},
                      {"sno":12,"label":"Declaration Form Pages","value":firstTableData.num_declaration_form_pages},
                      {"sno":13,"label":"Cheque Pages","value":firstTableData.num_cheque_pages}
      ]

      let pageNumberMap = new Object();
      if(secondTableData.bill_pages)
        secondTableData.bill_pages.forEach(element => {
            pageNumberMap[element] = "Bill Page";
        });

      if(secondTableData.discharge_summary_pages)
        secondTableData.discharge_summary_pages.forEach(element => {
            pageNumberMap[element] = "Discharge Summary Page";
        });

      if(secondTableData.nhi_pages)
        secondTableData.nhi_pages.forEach(element => {
            pageNumberMap[element] = "NHI Page";
        });

      if(secondTableData.others)
        secondTableData.others.forEach(element => {
            pageNumberMap[element] = "Others Page";
        });

      if(secondTableData.radiology_report_pages)
        secondTableData.radiology_report_pages.forEach(element => {
            pageNumberMap[element] = "Radiology Page";
        });

      if(secondTableData.lab_report_pages)
        secondTableData.lab_report_pages.forEach(element => {
            pageNumberMap[element] = "Lab Report Page";
        });
      
      if(secondTableData.kyc_pages)
        secondTableData.kyc_pages.forEach(element => {
            pageNumberMap[element] = "KYC Page";
        });

      if(secondTableData.pan_pages)
        secondTableData.pan_pages.forEach(element => {
            pageNumberMap[element] = "PAN Page";
        });

      if(secondTableData.aadhar_pages)
        secondTableData.aadhar_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar Page";
        });

      if(secondTableData.aadhar_pan_pages)
        secondTableData.aadhar_pan_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar PAN Page";
        });

      if(secondTableData.claim_form_pages)
        secondTableData.claim_form_pages.forEach(element => {
            pageNumberMap[element] = "Claim Form Page";
        });

      if(secondTableData.declaration_form_pages)
        secondTableData.declaration_form_pages.forEach(element => {
            pageNumberMap[element] = "Declaration Form Page";
        });

      if(secondTableData.cheque_pages)
        secondTableData.cheque_pages.forEach(element => {
            pageNumberMap[element] = "Cheque Page";
        });

      for (var key in pageNumberMap) {
          let rowIndex = new Object();
          rowIndex["pageno"] = key;
          rowIndex["category"] = pageNumberMap[key];

          this.secondRowData.push(rowIndex);
      }
      
    },
    data(){

        return{
          dataHeaders:[{"label":"Category","value":"category"},
                       {"label":"Count","value":"val"},
                       ],
          secondTableHeader:[
            {"label":"Category","value":"category"},
            {"label":"Pg No.","value":"label"},           
          ],
          rowData:[],
          secondRowData:[],
          loader:null,
          loading:false,
          idSelected:0,
          snackText:'',
          snackbar:false,
        }
    },
  }
</script>

<style>
  #t-heads{
    color:black !important;
    font-size: 1em;
  }
  .container{
    padding: 0px !important;
    /* margin:0px !important; */
  }
  
</style>