<template>
  <div class="home">
    <div style="padding:2%;margin-left:2.5%;">
      <v-row>
        <v-col>
          <v-btn depressed
            color="primary"
            @click="goBack"
            
          >
            Home
          </v-btn>
        </v-col>
        <v-col>
            <h2>Total Count Left: {{extractCount}}</h2>
        </v-col>
      </v-row>
    </div>
    <Extracted/>
  </div>
</template>

<script>
import Extracted from '@/components/Extracted.vue'
import axios from 'axios';
const encryptFunc = require('../js/helpers');

export default {
  name: 'Home',
  components: {
    Extracted
  },
  methods:{
    async goBack(){
      const decypt_inputFilename = encryptFunc.helpers.decryptString(this.$store.state.inputFileName);
      let inputFile = decypt_inputFilename;
      await this.deleteCurrentFile(inputFile);
      this.$router.push('/submission');
    },
    async deleteCurrentFile(inputFile){
      let reqObj = {file:inputFile};

      const url = process.env.VUE_APP_NODE_URL+"/deleteDocx";
      let response = await axios.post(url,reqObj);

      return response;
    }
  },
  created(){
    const decrypt_extractionCountLeft = encryptFunc.helpers.decryptJSON(this.$store.state.extractionCountLeft);
    this.extractCount = decrypt_extractionCountLeft.countDS;
  },
  data(){
    return{
      extractCount:0,
    }
  }
}
</script>
