import { render, staticRenderFns } from "./DrugPrice.vue?vue&type=template&id=706ec104&scoped=true&"
import script from "./DrugPrice.vue?vue&type=script&lang=js&"
export * from "./DrugPrice.vue?vue&type=script&lang=js&"
import style0 from "./DrugPrice.vue?vue&type=style&index=0&id=706ec104&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "706ec104",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VCol,VContainer,VFileInput,VFooter,VIcon,VImg,VProgressCircular,VRow,VSnackbar})
