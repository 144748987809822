<template>
    <div>
        <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);"></v-col>
        </v-row>
        
        <section id="container" style="height: 750px;margin-top:0.8%;"/>
    </div>
</template>


<script>

import * as pbi from "powerbi-client"

export default {
    name: 'PowerBI',
    props: ['id'],
    created(){
        document.title = "IHX Demo App"
    },
    mounted(){
        let varName = this.$store.state.dashboardLinks[this.id];

        let data = this.$store.state[varName];
        const permissions = pbi.models.Permissions.All
        const config = {
            type: 'report',
            tokenType: pbi.models.TokenType.Embed,
            accessToken: data.embedToken,
            embedUrl: data.embedUrl,
            id: data.reportId,
            pageView: 'fitToWidth',
            permissions: permissions,
        };

        let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        const dashboardContainer = document.getElementById('container');
        const dashboard = powerbi.embed(dashboardContainer, config);

        dashboard.off("loaded");
        dashboard.off("rendered");
        dashboard.on("error", function () {
            this.dashboard.off("error");
        });
    },
    data(){
        return{
            usernameInfo:this.$store.state.username,
        }
    },
  }
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
.foot-class{
    padding-bottom:0;
    padding:1%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
  }
</style>
