<template>
  <v-container>
    <v-card class="mx-auto"
    max-width="600" hover
    outlined style="padding:3%;margin:auto;"> 
    <h2>New Submission:</h2><br>
    <form id="inputForm" @submit.prevent="showTable()" enctype="multipart/form-data">
      <v-text-field
        label="Client Id"
        :rules="rules"
        id="txnIdInput"
        hide-details="auto"
        v-model="inputData.txnId"
        :disabled="dsdTableDataRecieved"
      ></v-text-field>

      <v-file-input id="fileInsert"
        show-size
        counter        
        accept="image/jpeg,application/pdf"        
        label="Attach File"
        v-model="inputData.fileInput"
        :disabled="dsdTableDataRecieved"
      ></v-file-input><br>
      <v-row>
        <v-col
            class="check_rows"
            cols="4"
            sm="4"
            md="6"
            v-for="check_values in this.checkbox_labels" :key="check_values.label"
        >
          <v-checkbox
                  v-model="selected_box"
                  :label="check_values.label"
                  color="black"
                  :value="check_values.value"
                  hide-details
                  :disabled="dsdTableDataRecieved"
          ></v-checkbox>
        </v-col>
      </v-row>
      <br><br>
      <v-btn color="blue-grey" class="ma-2 white--text" type="submit" :disabled="dsdTableDataRecieved">
        Submit
        <v-icon right dark> mdi-cloud-upload </v-icon>
      </v-btn>

      <v-progress-circular 
      v-if="dsdTableDataRecieved" 
      indeterminate
      color="black"></v-progress-circular>
    </form>

    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{snackText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<style>
  #fileInsert{
    width:10px;
  }
  .v-progress-circular {
  margin: 1rem;
}
  .check_rows{
    padding-top: 0in !important;
    padding-block-end:0px !important;
  }
</style>

<script>

  const encryptFunc = require('../js/helpers');
  const axios = require('axios').default;

  export default {
    name: 'HelloWorld',
    components: {
    },
    props:{
    },
    mounted(){
      this.dsdTableDataRecieved = false
    },
    methods:{
      async updateDSExtractionCount(id){
          const url = process.env.VUE_APP_NODE_URL+"/extractCount";
          let requestObj = {id:id};
          let response = await axios.post(url,requestObj);

          return response.data;
        },
      async classifierCountLeft(id){
          const url = process.env.VUE_APP_NODE_URL+"/classifierCount";
          let requestObj = {id:id};
          let response = await axios.post(url,requestObj);

          return response.data;
      },
      async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
      },
      async sleep(ms){
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },
      async callNodeForID(fileInputName){
        let start = Date.now();
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': this.inputData.txnId,'file':fileInputName};
        let idData = await axios.post(url,data);
        var final = (Date.now() - start)/1000;
        this.$store.commit('updateTxnAPItime',final);
        return idData;
      },
      async callNodeforJSON(txnID){
        let totalTries = 10;
        while(totalTries>0){
          const url = process.env.VUE_APP_NODE_URL+'/getJSON';
          let data = {'id': txnID};
          var response = await axios.post(url,data);
          
          if(!response.data)
              return false;
            
          // if(response.data.response.status=="failure")
          //     return false;
          if(response.data.response.status=="success")
              return response;
          if(totalTries==1)
              return response;
            await this.sleep(15000);
            totalTries-=1;
        }

        return response;
      },  
      async showTable () {
        try {
          
        

        if(this.inputData.fileInput==null || this.inputData.txnId==null)
        {
          this.snackText = "Input Details or DS File not provided!"
          this.snackbar = true;
          return false;
        }
        const decypted_userInfo = encryptFunc.helpers.decryptJSON(this.$store.state.userInfo);
        let userInfo = decypted_userInfo;

        if(this.selected_box=="doc_val" && userInfo.docFlag!=1)
        {
          this.snackbar = true;
          this.snackText = 'Service not allowed';
          return false;
        }

        if(this.selected_box=="dis_val" && userInfo.dsFlag!=1){
          this.snackbar = true;
          this.snackText = 'Service not allowed';
          return false;
        }

        this.dsdTableDataRecieved = true;
        
        let formData =  new FormData();
        formData.append("file", this.inputData.fileInput);
        formData.append("txnId",this.inputData.txnId);

        let formData2 = new FormData();
        formData2.append("reports", this.inputData.fileInput);
        formData2.append("caseid", this.inputData.txnId);

        let sendFileToUpload = await this.uploadFile(formData);
        this.$store.commit('updateInputFilename',this.inputData.fileInput.name);

        if(sendFileToUpload.status!=200){
          this.snackText = "File could not be uploaded.Please try again!";
          this.snackbar = true;
          this.dsdTableDataRecieved = false;
          return false;
        }

        if(sendFileToUpload.status==200){
          this.snackText = "File send to DSD Queue!!";
          this.snackbar = true;
          const decypted_filename = encryptFunc.helpers.decryptString(this.$store.state.inputFileName);
          let InputfileName = decypted_filename;
          var requestAPIinfo = await this.callNodeForID(InputfileName);
          var txnIdtoSend = requestAPIinfo.data.txnId;
          let containsDSPages = (requestAPIinfo.data.data.summary['num_discharge_summary_pages']>0)?1:0;
          this.$store.commit('updateContainsDSPages',containsDSPages);
          var perPageData = requestAPIinfo;
          this.$store.commit('updatePagePerData',perPageData);
        }

        if(!txnIdtoSend){
          this.snackText = "Could not process file. Please try again!";
          this.snackbar = true;
          this.dsdTableDataRecieved = false;
          return false;
        }
        if(this.selected_box=="doc_val"){
          const decrypt_userInfo = encryptFunc.helpers.decryptJSON(this.$store.state.userInfo);
          let countLeft = await this.classifierCountLeft(decrypt_userInfo.id);
          this.$store.commit('updateClassifierCount',countLeft);  

          if(countLeft.countDS<1){
            this.snackbar = true;
            this.snackText = 'Allowed number of requests exceeded.'
            this.dsdTableDataRecieved = false;
            return false;
          }
          this.$router.push('/pageInfo');
        } 

        else{
            this.snackbar = false;

            this.snackText = "Waiting for Processed Information. This can take a few minutes.";
            this.snackbar = true;

            let containsDSPages = encryptFunc.helpers.decryptedInteger(this.$store.state.containsDSPages);

            if(!containsDSPages){
              this.snackText = "File does not contain Discharge Summary Pages";
              this.snackbar = true;
              this.dsdTableDataRecieved = false;
              return false;
            }

            if(txnIdtoSend){
              this.snackText = "Waiting for Processed Information. This can take a few minutes.";
              this.snackbar = true;
              // var currentJSON = await this.getRespJSON(txnIdtoSend);
              var currentJSON = await this.callNodeforJSON(txnIdtoSend);
            }
            // console.log(currentJSON);

            if(!currentJSON.data){
              this.snackText = "Service not available. Please try again !!";
              this.snackbar = true;
              this.dsdTableDataRecieved = false;
              return false;
            }

            if(currentJSON.data.response.status=="accepted" || currentJSON.data.response.status=="failed"){
              this.snackText = "File could not be processed. Please try again!";
              this.snackbar = true;
              this.dsdTableDataRecieved = false;
              return false;
            }

            if(currentJSON.data.response.status=="success" && currentJSON.data.response.message=="no discharge summary pages"){
              this.snackText = "No Discharge Summary Found.Please try again!";
              this.snackbar = true;
              this.dsdTableDataRecieved = false;
              return false;
            }

            this.$store.commit('updateDSDjson',currentJSON);

            this.dsdTableDataRecieved =false;

            const decypt_userInfo = encryptFunc.helpers.decryptJSON(this.$store.state.userInfo);
            let countLeft = await this.updateDSExtractionCount(decypt_userInfo.id);
            this.$store.commit('updateExtractCount',countLeft);
            if(countLeft.countDS<1){
              this.snackbar = true;
              this.snackText = 'Allowed number of requests exceeded.'
              return false;
            }
            this.$router.push('/detail');
            // this.$router.push('/pageInfo');
        }
        } catch (error) {
          console.log(error);
        }
      }
    },
    data(){
      return{
      rules: [
        value => !!value || 'Required.',
      ],
        src:undefined,
        selected_box:'doc_val',
        checkbox_labels:[{'label':'Document Classifier','value':'doc_val'},
                         {'label':'DS Extraction','value':'dis_val'},
                        ],
        snackText:`I am an error`,
        multiLine:true,
        snackbar:false,
        timeout:10000,
        dsdTableDataRecieved:false,
        inputData:{
          txnId:null,
          fileInput:null,
          inputPass:null,
        }
      } 
    },
  }
</script>

