<template>
  <v-container fluid>
        <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
                <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
                <v-icon dark large style="padding-right:5%;">
                    mdi-account-circle
                </v-icon>
                <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
                <div class="drp-dwn" v-show="showDropdown">
                    <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
                </div>

                </div>
            </v-col>
            
        </v-row>

        <div style="margin-top:66.3px;width:100%;margin-left:84px;">
            <v-row dense>
                <v-col md="auto" lg="auto">
                    <v-img src="../assets/home_icon.svg" width="21px" style="margin-right:6px;float:left;cursor:pointer;" @click="goBack"></v-img>
                    <span id="home-id" @click="goBack">Home</span> <span id="arrow-id">>></span> 
                </v-col>

                <v-col md="3" lg="3">
                    <v-img src="../assets/folder_icon.svg" width="16px" style="float:left;margin-right:5px;margin-top:0.5%;"></v-img>
                    <span id="header-id">Drug Pricing Comparator</span>
                </v-col>
            </v-row>
        </div>

        <hr style="border: 0.7px dashed #9F9DE0;left:6.08%;right:8.2%;position:absolute;margin-top:23px;">

        <div style="margin-top:6%;margin-left:117px;" id="new-sub-id">
            New Submission
        </div>
        
        <v-row style="margin-left:130px;margin-top:29px;">
            <v-col md="6" lg="6">
                <v-file-input id="fileInsert"
                                show-size
                                counter        
                                accept="image/*,application/pdf"        
                                label="Attach Document"
                                v-model="fileInput"
                                :disabled="requestSent"
                                dense
                                outlined
                                prepend-inner-icon="mdi-cloud-upload"
                                prepend-icon=""
                                width="546px"
                                height="43px"
                                background-color="rgba(243, 243, 243, 0.43)"
                                style="font-style:italic;font-family: Open Sans;font-color: #A2A2A2;"
                >
                <v-img src="../assets/home_icon.svg"></v-img>
                </v-file-input>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-btn width="158px" height="43px" color="#2E2BEB" @click="submitInput()" :disabled="requestSent" class="white--text" style="font-family: Open Sans;font-weight:bolder;font-size:18px;line-height:26px;">Submit</v-btn>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-progress-circular 
                    v-if="loadingData" id="shownScroll"
                    indeterminate
                    color="#2E2BEB"
                    style="margin-top:-0.5%;"
                >
                </v-progress-circular>
            </v-col>
        </v-row>
      
      <v-row style="margin-top:76px;margin-left:117px;margin-bottom:111px;">
          <v-col md="6" lg="6" v-if="showTable">
              <span id="header-id" style="margin-left:-1%;">Results</span>
          </v-col>
          
          <v-col md="12" lg="12" v-if="showTable" style="margin-left:18px;margin-top: 29px;">
                    <v-col md="12" lg="12" style="border:2px #E4E4F754 solid;max-width:1200px;background: rgba(228, 228, 247, 0.33);" class="header-id"> 
                        <span style="color:#2E2BEB;">Total Overcharged Amount : {{totalOverchargedAmt}}</span>
                        <div style="float:right;">
                            <span class="dot" style="background-color: rgba(42, 191, 134, 0.2);"></span> Extracted <span style="color:rgba(228, 228, 247, 0.33);">-----</span> <span class="dot" style="background-color: rgba(60, 58, 218, 0.2);"></span> Matched
                        </div>
                    </v-col>
                <TableView :tableType="priceCompTable" :tableData="price_data" :tableHeaders="price_data_headers" :styleGroup="true" :tableRows="items_page"/>
          </v-col>
      </v-row>

      <v-footer v-if="showTable" dark padless style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class-show" style="padding-bottom:0%;">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          
          </v-row>
      </v-footer> 
      <v-footer v-if="!showTable" dark padless fixed height="45px" style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          </v-row>
      </v-footer> 
      
      <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar> 
  </v-container>
</template>

<script>
const axios = require('axios').default;
import TableView from '../components/TableView.vue'
import helpers from '../js/apiCalls'

export default {
  name: 'Encoder',
  components: {
      TableView
  },
  created(){
    document.title = "IHX Demo App";
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  methods:{
      changeState(e){
            let logoutButtonArea = this.$refs.logoutButton;
            
            if (!logoutButtonArea.contains(e.target)) {
                this.showDropdown = false
            }
      },
      doLogout(){
        this.$store.commit('resetState');
        this.$router.push('/').catch((e)=>{console.log(e);});
      },
      async checkPriceData(docId){

        let url = process.env.VUE_APP_NODE_URL+"/getPriceData";
        let reqObj = {'id' : docId};

        let totalTries = 15;

        while(totalTries > 0){
            this.snackbar = true;
            this.snacktext = 'Waiting for Extraction Results. Please wait!!';

            var response = await axios.post(url,reqObj);

            if(!response.data)
                return false;

            if(response.data == 'false')
                return false;

            if(response.data && response.data !== 'Processing')
                return response.data;

            await this.sleep(15000);
            totalTries-=1;
        }

        return false;
      },
      goBack(){
          this.$router.push('/main');
      },
      async submitInput(){
          this.showTable = false;
          this.imageInputHere = false;
          this.showResults = false;

          if(this.encoderInput == '' && this.fileInput == null){
              this.snackbar = true;
              this.snacktext = "No Input Provided!!";
              return false;
          }
          else{
              this.requestSent = true;
              this.loadingData = true;

              await this.createTableforImage();
          }
      },
      async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
      },
      async createTableforImage(){
            let formData =  new FormData();
            formData.append("file", this.fileInput);

            let sendFileToUpload = await helpers.saveFileLocally(this.$store.state.userInfo.id,this.fileInput);

            if(!sendFileToUpload){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                var priceDataId = await helpers.callPriceComp(sendFileToUpload);
                
                if(!priceDataId){
                    this.snackbar = true;
                    this.snacktext = "Failed To extract for input File. Please Try again!!";

                    this.loadingData = false;
                    this.requestSent = false;

                    return;
                }

                let priceData = await this.checkPriceData(priceDataId);

                this.totalOverchargedAmt = parseFloat(priceData.bill_data.map(item => item.overcharged_amount).reduce((prev, next) => prev + next)).toFixed(2);

                if(!priceData){
                    this.snackbar = true;
                    this.snacktext = "Failed To extract for input File. Please Try again!!";

                    this.loadingData = false;
                    this.requestSent = false;

                    return;
                }
                
                // await this.setImagePDF(false,sendFileToUpload)

                this.price_data = priceData.bill_data;

                this.showTable = true;
                this.showResults = true;
                this.imageFileInput = false;
                this.requestSent = false;
                this.loadingData = false;
        }
      },
      async sleep(ms){
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },
  },
  data(){
      return {
          showDropdown: false,
          imageInputHere:false,
          snackbar : false,
          snacktext : '',
          encoderInput : '',
          requestSent: false,
          showResults: false,
          priceCompTable: 'DrugComp',
          encoderResults:'',
          loadingData: false,
          fileInput: null,
          items_page:10,
          totalOverchargedAmt:0,
          price_data: [],
          price_data_headers:[
                                    {text: 'Bill Item',value:'particular',sortable:false,className:'red-line'},
                                    {text: 'Quantity',value: 'unit',sortable:false},
                                    {text: 'Rate',value: 'rate',sortable:true},
                                    {text: 'ADA', value: 'Net_amount',sortable:false},
                                    {text: 'Matched Item', value: 'matched_item',sortable:false},
                                    {text: 'MRP', value: 'mrp',sortable:false},
                                    {text: 'Composition', value: 'salt_composition',sortable:false},
                                    {text: 'Packaging', value: 'packaging',sortable:false},
                                    {text: 'Final Rates', value: 'final_rates',sortable:false},
                                    {text: 'Percentage Match', value: 'percentage_match',sortable:false},
                                    {text: 'Overcharged Amount', value: 'overcharged_amount',sortable:true},
                                    {text: 'Overcharged Percentage', value: 'overcharged_percentage',sortable:false},
                                ],
          showTable:false,
          imageFileInput:false,
          usernameInfo:this.$store.state.username,
          imgSource:'',
          activeTab:0,
          cirValue:0,
      }
  },
}
</script>

<style scoped>

#arrow-id{
    vertical-align:top;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    margin-left:16px;
    margin-right:16px;
    color: #4B49C9;
}
#home-id{
    cursor:pointer;
    width: 59px;
    height: 22px;
    left: 111px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: rgba(73, 70, 197, 0.53);
}
#header-id{
    width: 325px;
    height: 22px;
    left: 232px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: #4946C5;
}
.dot {
  height: 1.5vh;
  width: 1.5vh;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
}
#new-sub-id{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #4946C5;
}
#user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}
.foot-class{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
    margin-top:12px;
}
.foot-class-show{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
}
#table-headers-active{
    width: 137px;
    /* left: calc(50% - 137px/2 + 109.5px); */
    /* top: 40.68%; */
    /* bottom: 56.95%; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */

    text-align: center;
    background-color: white;
    color: #2E2BEB;
    border: 1px solid #D3D3D3;
    border-bottom:none;
}
.drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
  }
#table-headers-inactive{
    width: 137px;
    /* left: calc(50% - 137px/2 + 109.5px); */
    /* top: 40.68%; */
    /* bottom: 56.95%; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */
    text-align: center;
    color: rgba(75, 73, 201, 0.61);
    background-color: rgba(228, 228, 247, 0.33);
    border: 1px solid #D3D3D3;
    border-right:none;
    border-left:none;
}
#table-headers-inactive:hover{
    background-color: rgba(228, 228, 247, 0.33) !important;
}
#table-headers-active:hover{
    background-color: transparent !important;
}
</style>
