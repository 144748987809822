<template>
  <v-container>
    
      <div>
          <TableView tableType="Complete Info" tableRows="15" :tableData="finalData" :tableHeaders="finalDataHeaders"/>
      </div>
   
  </v-container>
</template>

<script>
  const encryptFunc = require('../js/helpers');
  import TableView from './TableView.vue'
  export default {
    name: 'XLExtraction',
    methods:{
        
    },
    components:{
        TableView,
    },
    async created(){     
      const decrypted_finalTable = encryptFunc.helpers.decryptJSON(this.$store.state.finalTable);
      this.finalData = decrypted_finalTable.data.final_data;

    },
    data(){

        return{
          finalData:null,
          finalDataHeaders:[{
                      text: 'Level',
                      align: 'start',
                      sortable: false,
                      value: 'level',
                      class: 'headerStyle'
                    },
                { text: 'Disease', value: 'disease' },
                { text: 'Conditions', value: 'condition' },
                { text: 'Negation', value: 'negation' },
                { text: 'Procedure', value: 'procedure'},
                { text: 'Radiology', value: 'radiology'},
                ],
        }
    },
  }
</script>

<style scoped>


</style>