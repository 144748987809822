<template>
  <div class="home">
      <v-row class="row-val">
        <v-col md="7" lg="7" class="col-val">
          
          <v-img src="../assets/ihx_logo.png"
                max-width="154px"
                style="display: block;margin-left:232px;margin-top:40px;"
          ></v-img>

          <div class="welcome-header">Welcome to IHX Demo Platform</div>

          <form id="inputForm" @submit.prevent="checkLogin()" enctype="multipart/form-data">
                <v-row no-gutters>
                  <v-col md="12" lg="12">
                      <v-text-field
                          label="Username"
                          id="userName"
                          hide-details="auto"
                          :disabled="loginButtonDisabled"
                          v-model="user_info"
                          outlined
                          class="input-btns-user"
                          dense
                          height="37px"
                          autocomplete="username"
                      ></v-text-field>
                  </v-col>

                  <v-col md="12" lg="12">
                      <v-text-field
                          label="Password"
                          id="inputPass"
                          hide-details="auto"
                          :disabled="loginButtonDisabled"
                          v-model="pass_info"
                          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show3 = !show3"
                          :type="show3 ? 'text' : 'password'"
                          outlined
                          dense
                          height="37px"
                          class="input-btns-pass"
                          autocomplete="new-password"
                      ></v-text-field><br>
                  </v-col>

                  <v-col md="12" lg="12" style="margin-left:105px;">
                      <v-btn color="#2E2BEB" class="ma-2 white--text" type="submit" :disabled="loginButtonDisabled" style="font-weight:bolder;width:404px;height:32px;">
                          Login
                      </v-btn>
                  </v-col>
                </v-row>
                </form>

        </v-col>
        <v-col md="5" lg="5">
          <v-img src="../assets/login_illus.svg"
                max-width="294px"
                style="display: block;margin: 0 auto;margin-top:86px;"
          ></v-img>

          <div class="abt-head">About IHX</div>

          <div class="abt-cont">IHX is India’s largest health information exchange platform, infused with AI/ML capabilities, delivering solutions to stakeholders in all segments of the healthcare ecosystem.</div>
        </v-col>
      </v-row>
    <v-snackbar
        v-model="snackbar"
        style="margin-bottom:2%;"
        >
        {{snackText}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar>

      <v-footer dark padless style="position:fixed;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              For any queries, please write to us at <u><a :href="`mailto:care@ihx.in`" style="color:inherit;">care@ihx.in</a></u><br>
              ©2023, IHX Private Limited. All Rights Reserved.

            </v-col>
          
          </v-row>
      </v-footer> 
  </div>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
// import CryptoJS from 'crypto-js'
const axios = require('axios');
export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  created(){
    document.title = "IHX Demo App"
    
    setTimeout(() => {
        document.getElementById("userName").focus();
        // setTimeout(() => {
        //   document.getElementById("userName").focus();
        // }, 200); 
    }, 400);
    
  },
  methods:{
      async checkLogin(){

        if(this.user_info == null && this.passwordInput == null){
          this.snackText = 'No Credentials provided.'
          this.snackbar = true;
        }
          
        const usernameInput = this.user_info;
        const passwordInput = this.pass_info;
                
        let response = await this.checkLoginInfo(usernameInput,passwordInput);       

        if(response.id == null){
            this.snackText = 'Wrong Credentials provided.'
            this.snackbar = true;
        }
        else{
            this.$store.commit('updateUserInfo',response);
            this.$store.commit('updateUsername',this.user_info);
            this.$router.push('/main');
        }
      },
      async checkLoginInfo(username,password){
          let requestObject = {username:username,password:password};
          const url = process.env.VUE_APP_NODE_URL+"/login";
          let response = await axios.post(url,requestObject);

          return response.data;
      }
  },
  watch: {
    // user_info : function(){
    //   clearInterval(this.inputCheckInterval);
    // }
  },
  data(){
      return{
          snackbar:false,
          snackText:'',
          loginButtonDisabled: false,
          user_info: null,
          pass_info: null,
          autofilled:false,
          show3:false,
          rules: [
                value => !!value || 'Required.',
          ],
      }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
.home{
  font-family: 'Open Sans';
  /* margin: 0; */
}
.row-val{
  position: absolute;
  width: 1161px;
  height: 466px;
  right: 0;
  left: 0;
  top: 0;
  bottom:0;
  margin:auto;

  background: #FFFFFF;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);
}
.col-val{
  width: 623px;
  height: 466px;
  left: 102px;
  top: 111px;
  /* text-align:center; */

  background: #F8FAFC;
}
.welcome-header{
  line-height: 22px;
  font-family: Open Sans;
  font-style: normal;
  margin-top: 36.75px;
  color: #2E2BEB;
  font-weight: 600;
  font-size: 18px;
  margin-left:10em;
}
.abt-head{
  line-height: 16.34px;
  font-family: Open Sans;
  font-style: normal;
  margin-top: 36.75px;
  color: #2E2BEB;
  font-weight: bold;
  font-size: 12px;
  margin-left:232px;
  margin-bottom:8px;
}
.input-btns-user{
  /* height:37px !important; */
  margin-top:37px;
  margin-bottom:24px;
  margin-left:112px;
  width:404px;
  
}
.input-btns-pass{
  width:404px;
  margin-bottom:20px;
  margin-left:112px;
  /* max-height:37px !important; */
}

 .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }

  .abt-cont{
    width: 386px;
    /* height: 48px; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-left:55px;
  }
  .foot-class{
    padding-bottom:0;
    padding:2%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-left:108px;
  }
  .custom-label-color > .v-label {
    color: red;
    opacity: 1;
  }

</style>
