<template>
  <v-container>
    <v-card class="mx-auto"
    max-width="400" hover
    outlined style="padding:3%;margin-top:20%;"
    > 
      <form id="inputForm" @submit.prevent="showTable()" enctype="multipart/form-data">
          <v-select
            :items="csvList"
            label="Standard"
            v-model="csvInput"
          >
          </v-select>
          <v-btn color="blue-grey" class="ma-2 white--text" type="submit">
            Display
          <v-icon right dark> mdi-cloud-upload </v-icon>
      </v-btn>
      </form>
    </v-card>
  </v-container>
</template>

<script>

  const axios = require('axios');
  // import TableView from './TableView.vue'
  export default {
    name: 'ListCSV',
    methods:{
        async callNodeForData(xlPath){
          const url = process.env.VUE_APP_NODE_URL+'/convertXLtoJSON';
            let data = {'path': xlPath};
            let responseForTables = await axios.post(url,data);
            console.log(responseForTables);
            return responseForTables;
        },
        
        async showTable(){
          if(this.csvInput == null)
              return false;

          this.$store.commit('updateCSVNumber',this.csvInput);    
          let responseForTables = await this.callNodeForData(this.csvInput);

          this.$store.commit('updateDSDjson',responseForTables);
          // let dataToSend = {"path":this.csvInput};        

          this.$router.push('/display');
        }
    },
    components:{
        // TableView,
    },
    data(){

        return{
            csvList:['1. Max 4 DS Dr Vasudeva','2. DS 10 Dr Vasudeva','3. DS lilavati Dr Vasudeva','4. DS shri Dr. Vasudeva','5. Cholecystectomy DS tagged_Redacted','6. Knee surgery DS tagged_Redacted','7. LSCS DS tagged_Redacted copy','8. Maxilo mandibular osteotomy DS tagged_Redacted - 1','123373_covid','123484_covid'],
            csvInput: null,
        }
    },
  }
</script>

<style scoped>


</style>