<template>
  <v-container fluid>
      <h1 style="color:white;padding:1%;background-color:#10b0ad;">Indexer</h1>

      <div style="padding:2%;">
          <v-btn style="float:left;" outlined color="pink dark-1" @click="goBack()">Home</v-btn>
      </div>

      <v-card class="mx-auto"
                        max-width="450"
                        outlined style="padding:2%;margin-top:2%;">
                    <h2>New Submission:</h2><br>
                    
                    <v-file-input id="fileInsert"
                        show-size
                        counter        
                        accept="application/pdf"        
                        label="Attach File"
                        v-model="fileInput"
                        :disabled="requestSent"
                    ></v-file-input><br>
                    <v-btn color="purple dark-4" outlined @click="submitInput()" :disabled="requestSent">Submit</v-btn>
                    <v-progress-circular 
                        v-if="loadingData" id="shownScroll"
                        indeterminate
                        color="black">
                    </v-progress-circular>
        </v-card>
        <v-row style="margin-top:3%;padding:3%;">
          <v-col md="6" lg="6">
              <v-card class="mx-auto"
                            max-width="700"
                            outlined style="padding:2%;margin-top:2%;" v-show="showOutput">
                <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%" style="display:none;"></iframe>
                <img id="inputImage" width="0%" height="0%" style="border:2px solid black;display:none;">
              </v-card>
          </v-col>
          <v-col md="6" lg="6">
            <v-card class="mx-auto"
                            max-width="500"
                            outlined style="padding:2%;margin-top:2%;" v-show="showOutput">
              <v-simple-table fixed-header class="simple-table-2">
                <template v-slot:default>
                  <thead class="t-heads">
                      <tr>
                          <th class="text-center" id="t-heads" v-for="header in secondTableHeader" :key="header.value">
                              {{header.label}}
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr
                        v-for="item in secondRowData"
                        :key="item.pageno"
                        class="text-center"
                        @click = "scrollToPage(item.pageno)"
                        >
                        <td>{{ item.category }}</td>
                        <td>{{item.pageno}}</td>
                      </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
          <v-snackbar
            v-model="snackbar"
            >
            {{snacktext}}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
          </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import helpers from '../js/apiCalls'

// const encryptFunc = require('../js/helpers');

export default {
  name: 'Indexer',
  components: {
  },
  created(){
    document.title = "IHX Demo App";
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  methods:{
    goBack(){
      this.$router.push('/main');
    },
    async prepareTableOutput(respObj){
      let finalData = respObj.data.data;

      let keyHere = Object.keys(finalData.details)[0];    
      let secondTableData = finalData.details[`${keyHere}`];

      let pageNumberMap = new Object();
      if(secondTableData.bill_pages)
        secondTableData.bill_pages.forEach(element => {
            pageNumberMap[element] = "Bill Page";
        });

      if(secondTableData.discharge_summary_pages)
        secondTableData.discharge_summary_pages.forEach(element => {
            pageNumberMap[element] = "Discharge Summary Page";
        });

      if(secondTableData.nhi_pages)
        secondTableData.nhi_pages.forEach(element => {
            pageNumberMap[element] = "NHI Page";
        });

      if(secondTableData.others)
        secondTableData.others.forEach(element => {
            pageNumberMap[element] = "Others Page";
        });

      if(secondTableData.radiology_report_pages)
        secondTableData.radiology_report_pages.forEach(element => {
            pageNumberMap[element] = "Radiology Page";
        });

      if(secondTableData.lab_report_pages)
        secondTableData.lab_report_pages.forEach(element => {
            pageNumberMap[element] = "Lab Report Page";
        });
      
      if(secondTableData.kyc_pages)
        secondTableData.kyc_pages.forEach(element => {
            pageNumberMap[element] = "KYC Page";
        });

      if(secondTableData.pan_pages)
        secondTableData.pan_pages.forEach(element => {
            pageNumberMap[element] = "PAN Page";
        });

      if(secondTableData.aadhar_pages)
        secondTableData.aadhar_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar Page";
        });

      if(secondTableData.aadhar_pan_pages)
        secondTableData.aadhar_pan_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar PAN Page";
        });

      if(secondTableData.claim_form_pages)
        secondTableData.claim_form_pages.forEach(element => {
            pageNumberMap[element] = "Claim Form Page";
        });

      if(secondTableData.declaration_form_pages)
        secondTableData.declaration_form_pages.forEach(element => {
            pageNumberMap[element] = "Declaration Form Page";
        });

      if(secondTableData.cheque_pages)
        secondTableData.cheque_pages.forEach(element => {
            pageNumberMap[element] = "Cheque Page";
        });

      this.secondRowData = [];

      for (var key in pageNumberMap) {
          let rowIndex = new Object();
          rowIndex["pageno"] = key;
          rowIndex["category"] = pageNumberMap[key];

          this.secondRowData.push(rowIndex);
      }
    },
    async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
    },
    async identifyPage(){
      let formData =  new FormData();
            formData.append("file", this.fileInput);

            let sendFileToUpload = await helpers.saveFileLocally(this.$store.state.userInfo.id,this.fileInput);

            if(!sendFileToUpload){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                var requestAPIinfo = await this.callNodeForID(sendFileToUpload);

                let returnObj = {requestAPIinfo:requestAPIinfo, newFileName: sendFileToUpload};

                return returnObj;
            }
    },
    async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
    async callNodeForID(newFilename){
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': 101,'file':newFilename};
        let idData = await axios.post(url,data);

        return idData;
    },
    async submitInput(){
      if(this.fileInput == null){
        this.snacktext = 'No Input File Provided !!';
        this.snackbar = true;

        return;
      }

      this.showOutput = false;
      this.requestSent = true;
      this.loadingData = true;

      let pageIdentifier = await this.identifyPage();

      let newFileName = pageIdentifier.newFileName;

      pageIdentifier = pageIdentifier.requestAPIinfo;

      if(!pageIdentifier){
        this.requestSent = false;
        this.loadingData = false;

        this.snackbar = true;
        this.snacktext = 'Service Failed. Please try again.'
      }
      console.log(pageIdentifier);
      await this.prepareTableOutput(pageIdentifier);

      let fileData = await this.getDocx(newFileName);

      var blob = new Blob([fileData.data], {type: 'application/pdf'});
      var pdfURL = window.URL.createObjectURL(blob);

      if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf"){
          let imageOutput = document.getElementById("inputImage");
          imageOutput.style.display = 'none';
          let iFrame = document.getElementById("iFrame");
          iFrame.style.width = '100%';
          iFrame.style.height = '600px';
          iFrame.style.display = 'block';
          iFrame.src = pdfURL;
        }
      else{
          let iFrame = document.getElementById("iFrame");
          iFrame.style.display = 'none';
          let imageOutput = document.getElementById("inputImage");
          imageOutput.style.height = '100%';
          imageOutput.style.width = '100%';
          imageOutput.style.display = 'block';
          imageOutput.src = pdfURL;
      }


      this.showOutput = true;
      this.requestSent = false;
      this.loadingData = false;
    }
  },
  data(){
    return{
        requestSent:false,
        loadingData:false,
        fileInput:null,
        snackbar:false,
        snacktext:'',
        showOutput:false,
        secondRowData:[],
        secondTableHeader:[
            {"label":"Category","value":"category"},
            {"label":"Pg No.","value":"label"},           
          ],
    }
  }
}
</script>
