<template>
  <div class="home">
    <ListCSV/>
  </div>
</template>

<script>
import ListCSV from '@/components/ListCSV.vue'

export default {
  name: 'List',
  components: {
    ListCSV
  },
  created(){
    document.title = "IHX Demo App"
  }
}
</script>
