import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Details from '../views/Details.vue'
import List from '../views/List.vue'
import Display from '../views/Display.vue'
import FurtherDetail from '../views/FurtherDetail.vue'
import PageInfo from '../views/PageInformation.vue'
import Login from '../views/Login.vue'
import Encoder from '../views/Encoder.vue'
import DocClassifier from '../views/DocClassifier.vue'
import Indexer from '../views/Indexer.vue'
import Main from '../views/MainPage.vue'
import FlowDocx from '../views/FlowDocx.vue'
import FlowDSD from '../views/FlowDSD.vue'
import Insights from '../views/Insights.vue'
import PowerBI from '../components/PowerBI.vue'
import LabDigitizer from '../views/LabDigitizer.vue'
import Payable from '../views/Payable.vue'
import PPI from '../views/PPI.vue'
import DrugPrice from '../views/DrugPrice.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/check',
    name: 'List',
    component: List
  },
  {
    path: '/dashboard/:id',
    name: 'PowerBI',
    component: PowerBI,
    props: true
  },
  {
    path: '/drugPrice',
    name: 'DrugPrice',
    component: DrugPrice
  },
  {
    path: '/PII',
    name: 'PPI',
    component: PPI
  },
  {
    path: '/payable',
    name: 'Payable',
    component: Payable
  },
  {
    path: '/detail',
    name: 'Details',
    component: Details
  },
  {
    path: '/submission',
    name: 'Home',
    component: Home
  },
  {
    path: '/flowDocx',
    name: 'FlowDocx',
    component: FlowDocx
  },
  {
    path: '/labDigitizer',
    name: 'LabDigitizer',
    component: LabDigitizer
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/display',
    name: 'Display',
    component: Display
  },
  {
    path: '/further',
    name: 'Further',
    component: FurtherDetail
  },
  {
    path:'/pageInfo',
    name:'Pages',
    component: PageInfo
  },
  {
    path:'/medicalEncoder',
    name:'Encoder',
    component: Encoder
  },
  {
    path:'/docxClassifier',
    name:'DocClassifier',
    component: DocClassifier
  },
  {
    path:'/indexer',
    name:'Indexer',
    component: Indexer
  },
  {
    path:'/main',
    name:'Main',
    component: Main
  },
  {
    path:'/flowDSD',
    name:'flowDSD',
    component: FlowDSD
  },
  {
    path:'/insights',
    name:'Insights',
    component: Insights
  }
]

const router = new VueRouter({
  scrollBehavior (){
    return {x:0,y:0};
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
