<template>
  <v-container>
    <v-btn
      class="ma-2"
      outlined
      color="indigo"
      @click="showFurther"
      right
    >
      Show Further
    </v-btn>
    <v-row>
      <v-col md="6" lg="6">
        <iframe id="iFrame" width="100%" height="50%"></iframe>
      </v-col>
    <v-col md="6" lg="6">
      <div>
          <TableView tableType="Diseases" :tableData="final_clinical_data" :tableHeaders="clinical_data_headers"/>
          <TableView tableType="Negations" :tableData="final_negations_data" :tableHeaders="negations_data_headers"/>
          <TableView tableType="Conditions" :tableData="final_conditions_data" :tableHeaders="conditions_data_headers"/>
          <TableView tableType="Procedures" :tableData="final_procedures_data" :tableHeaders="procedures_data_headers"/>
          <TableView tableType="Radiology" :tableData="final_radiology_data" :tableHeaders="procedures_data_headers"/>


          <!-- <TableView tableType="third"/> -->
          <!-- <TableView tableType="fourth"/> -->
      </div>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>

  const axios = require('axios');
  import TableView from './TableView.vue'
  export default {
    name: 'XLExtraction',
    methods:{
        async getDocx (pdfURL) {
          const url = process.env.VUE_APP_NODE_URL+"/getPDF";
          let data = {'path': pdfURL};
          let response = await axios.post(url,data,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });

          return response;
        },
        async showFurther(){
          
          this.$router.push('/further');
        }
    },
    components:{
        TableView,
    },
    async created(){     
        try {
        
        var csvPicked = this.$store.state.pickCSVNumber;
        var responseForTables = this.$store.state.dsdTableJSON;

        var pdfPath = csvPicked+".pdf";
        // var xlPath = csvPicked+".xlsx";
       
        let final_clinical_data = responseForTables.data.final_clinical_data;
        let final_negations_data = responseForTables.data.final_negations_data;
        let final_procedures_data = responseForTables.data.final_procedures_data;
        let final_conditions_data = responseForTables.data.final_conditions_data;
        let final_radiology_data = responseForTables.data.final_radiology_data;

        // console.log(final_clinical_data[0]);

        this.final_clinical_data = final_clinical_data;
        this.final_negations_data = final_negations_data;
        this.final_procedures_data = final_procedures_data;
        this.final_conditions_data = final_conditions_data;
        this.final_radiology_data = final_radiology_data;

        let fileData = await this.getDocx(pdfPath); 
        

        var blob = new Blob([fileData.data], {type: 'application/pdf'});
        var pdfURL = window.URL.createObjectURL(blob);
        var iFrame = document.getElementById("iFrame");
        iFrame.src = pdfURL;

        let data = {"path":this.$store.state.pickCSVNumber};
        const urlFinalTable = process.env.VUE_APP_NODE_URL+"/setFinalTable";
        let response = await axios.post(urlFinalTable,data);
          
        this.$store.commit('updateFinalTable',response);  
      } catch (error) {
          console.log(error);
        }
    },
    data(){

        return{
            clincical_data: null,
            negations: null,
            principal_disease: null,
            procedures: null,
            fileURL:null,
            final_clinical_data:null,
            final_negations_data:null,
            final_procedures_data:null,
            final_conditions_data:null,  
            final_radiology_data:null,
            clinical_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'extracted_entity',
                      class: 'headerStyle'
                    },
                { text: 'SNOMED Entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'ICD-10 CM', value: 'icd_code' },
                { text: 'Level', value: 'level'},
                ],
            conditions_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED Entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'ICD-10 CM', value: 'icd_code' },
                { text: 'Level', value: 'level'},],
            negations_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'Level', value: 'level'},],
            procedures_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'Level', value: 'level'},
                ]

        }
    },
  }
</script>

<style scoped>

.mx-auto{
    margin-bottom: 5%;
}


</style>