<template>
  <div class="home">
    <FinalTable/>
  </div>
</template>

<script>
import FinalTable from '@/components/FinalTable.vue'

export default {
  name: 'Further',
  components: {
    FinalTable
  },
  created(){
    document.title = "IHX Demo App"
  }
}
</script>
