<template>
  <v-container fluid>
      <h1 style="color:white;padding:1%;background-color:#10b0ad;">Medical Autoencoder</h1>      
      <!-- <div style="padding:2%;margin-bottom:-2%;">
          <v-btn outlined color="pink dark-1" @click="goBack()">Home</v-btn>
      </div> -->
            
        <v-row style="padding:3%">
            <v-col md="6" lg="6">
                <v-card class="mx-auto"
                        max-width="700"
                        outlined style="padding:3%;margin-top:2%;" v-show="showResults">
                    <v-card-title>Output</v-card-title>
                    <div v-html="encoderResults" v-show="imageFileInput"></div>
                        <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%"></iframe>
                        <img id="inputImage" width="0%" height="0%" style="border:2px solid black;">
                </v-card>
            </v-col>
            <v-col md="6" lg="6">
                <v-card class="mx-auto"
                    max-width="1200"
                    outlined style="padding:3%;margin-top:2%;margin-bottom:2%;" 
                    v-if="showTable">
                    <v-card-title>Tables</v-card-title>
                    <TableView tableType="Principal Diagnosis" :tableData="final_principal_diagnosis_data" :tableHeaders="principal_diagnosis_data_headers"/><br>
                    <TableView tableType="Diseases" :tableData="final_clinical_data" :tableHeaders="clinical_data_headers"/><br>
                    <TableView tableType="Conditions" :tableData="final_conditions_data" :tableHeaders="conditions_data_headers"/><br>
                    <TableView tableType="Procedures" :tableData="final_procedures_data" :tableHeaders="procedures_data_headers"/>
                </v-card>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar>
  </v-container>
</template>

<script>
const encryptFunc = require('../js/helpers');
const axios = require('axios').default;
import TableView from '../components/TableView.vue'

export default {
  name: 'Encoder',
  components: {
      TableView
  },
  created(){
    document.title = "IHX Demo App"

    this.submitInput();
  },
  methods:{
      goBack(){
          this.$router.push('/main');
      },
      async submitInput(){
        await this.createTableforImage();
      },
      
      async createTableforImage(){     
        
        let tableData = this.$store.state.dsdOutput;

        let prepareTables = await encryptFunc.helpers.prepareTables(tableData.data.response);

        this.final_clinical_data = prepareTables.finalTableData.final_clinical_data;
        this.final_conditions_data = prepareTables.finalTableData.final_conditions_data;
        this.final_procedures_data = prepareTables.finalTableData.final_procedures_data;
        this.final_principal_diagnosis_data = prepareTables.finalTableData.final_principal_diagnosis_data;

        this.clinical_data_headers = prepareTables.finalHeaderData.clinical_data_headers;
        this.conditions_data_headers = prepareTables.finalHeaderData.conditions_data_headers;
        this.procedures_data_headers = prepareTables.finalHeaderData.procedures_data_headers;
        this.principal_diagnosis_data_headers = prepareTables.finalHeaderData.principal_diagnosis_data_headers;

        // this.encoderResults = respResults;

        let fileData = await this.getDocx(this.$store.state.inputFileName);
        var blob = new Blob([fileData.data], {type: 'application/pdf'});
        var pdfURL = window.URL.createObjectURL(blob);

        if(this.$store.state.inputFileName.split(".")[this.$store.state.inputFileName.split(".").length-1].toLowerCase() == "pdf"){
            let iFrame = document.getElementById("iFrame");
            iFrame.style.width = '100%';
            iFrame.style.height = '600px';
            iFrame.src = pdfURL;
            document.getElementById("inputImage").style.display = 'none';
        }
        else{
            let iFrame = document.getElementById("iFrame");
            iFrame.style.display = 'none';
            var imageOutput = document.getElementById("inputImage");
            imageOutput.style.height = '100%';
            imageOutput.style.width = '100%';
            imageOutput.src = pdfURL;
        }
        this.showTable = true;
        this.showResults = true;
        this.imageFileInput = true;
        this.requestSent = false;
        this.loadingData = false;

      },
      async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
  },
  data(){
      return {
          snackbar : false,
          snacktext : '',
          encoderInput : '',
          requestSent: false,
          showResults: false,
          encoderResults:'',
          loadingData: false,
          fileInput: null,
          final_clinical_data:[],
          final_conditions_data:[],
          final_procedures_data:[],
          final_principal_diagnosis_data:[],
          clinical_data_headers:[],
          conditions_data_headers:[],
          procedures_data_headers:[],
          principal_diagnosis_data_headers:[],
          showTable:false,
          imageFileInput:false,
      }
  },
}
</script>

<style scoped>

</style>
