<template>
        <v-card
                max-width="1200"
                outlined tile :style="(styleGroup)?'secondary-style':'default-style'"
        > 
            <!-- <v-card-title>
                {{tableType}}
            </v-card-title>     -->
                    <v-data-table
                        id="myTable"
                        :headers="tableHeaders"
                        :items="tableData"
                        :items-per-page="tableRows?tableRows:5"
                        class="mytable"
                    >

                        <template v-slot:item="{item}" v-if="tableType == 'DrugComp'">
                            <tr>
                                <td class="style-1">{{item.particular}}</td>
                                <td class="style-1">{{item.unit}}</td>
                                <td class="style-1">{{item.rate}}</td>
                                <td class="style-1">{{item.Net_amount}}</td>
                                <td class="style-2">{{item.matched_item}}</td>
                                <td class="style-2">{{item.mrp}}</td>
                                <td class="style-2">{{item.salt_composition}}</td>
                                <td class="style-2">{{item.packaging}}</td>
                                <td class="style-2">{{item.final_rates}}</td>
                                <td class="style-2">{{item.percentage_match}}</td>
                                <td class="style-2">{{item.overcharged_amount}}</td>
                                <td class="style-2">{{item.overcharged_percentage}}</td>
                            </tr>
                        </template>

                    </v-data-table>
        </v-card>
</template>


<script>

export default {
    name: 'TableView',
    props: ['tableType','tableData','tableHeaders','tableRows','styleGroup'],
    data(){
        return{
            search:'Condition',
            headers:[
            ],
            tableItems:[
            ],
            showFlag:false,
        }
    },
  }
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
    .style-1{
        background-color: rgba(42, 191, 134, 0.2);
    }
    .style-2{
        background-color: rgba(60, 58, 218, 0.2);
    }
    .elevation-1{
        text-align: center;
    }
    #myTable {
        color:black;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        font-size: 12px !important;
        text-align:center !important;
        font-weight: bold !important;
        font-family: Open Sans !important;
        color: #1C2024 !important;
        /* color : red !important; */
    }
    .v-data-table > .v-data-table__wrapper > table > tfoot > tr{
        font-size: 100px !important;
    }
    .v-data-footer__select{
        font-size: 10px !important;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 300;
        color: #262626;
        text-transform: uppercase;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        /* or 123% */
        text-align-last : center;
        color: #1C2024;
    }
    .default-style{
        padding:3%;
        border-top:none;
    }
    .secondary-style{
        padding:3%;
    }
    .v-data-table-header th {
        white-space: nowrap;
    }
</style>