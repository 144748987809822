import CryptoJS from 'crypto-js'

let encryptString = function(value){
    const encryptedText = CryptoJS.AES.encrypt(value, process.env.VUE_APP_SECRET_KEY).toString();

    return encryptedText;
}

let decryptString = function(encryptedText){
    const decryptedText = CryptoJS.AES.decrypt(encryptedText, process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);

    return decryptedText;
}

let encryptJSON = function(jsonObj){
    const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(jsonObj), process.env.VUE_APP_SECRET_KEY).toString();

    return encryptedText;
}

let decryptJSON = function(encryptedText){
    const decryptedText = CryptoJS.AES.decrypt(encryptedText, process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    const JSONObj = JSON.parse(decryptedText);
    return JSONObj;
}

let encryptInteger = function(value){
    let strVal = value.toString();
    const encryptedText = CryptoJS.AES.encrypt(strVal, process.env.VUE_APP_SECRET_KEY).toString();

    return encryptedText;
}

let decryptedInteger = function(encryptedText){
    const decryptedText = CryptoJS.AES.decrypt(encryptedText, process.env.VUE_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    let intVal = parseInt(decryptedText);

    return intVal;
}

let entitiesCSS = function(){
    return '<style>.entities { line-height: 2; } [data-entity] { padding: 0.25em 0.35em; margin: 0px 0.25em; line-height: 1; display: inline-block; border-radius: 0.25em; border: 1px solid; } [data-entity]::after { box-sizing: border-box; content: attr(data-entity); font-size: 0.6em; line-height: 1; padding: 0.35em; border-radius: 0.35em; text-transform: uppercase; display: inline-block; vertical-align: middle; margin: 0px 0px 0.1rem 0.5rem; } [data-entity][data-entity="conditions"] { background: rgba(166, 226, 45, 0.2); border-color: rgb(166, 226, 45); } [data-entity][data-entity="conditions"]::after { background: rgb(166, 226, 45); } [data-entity][data-entity="disease"] { background: rgba(224, 0, 132, 0.2); border-color: rgb(253, 151, 32); } [data-entity][data-entity="disease"]::after { background: rgb(253, 151, 32); } [data-entity][data-entity="procedures"] { background: rgba(253, 151, 32, 0.2); border-color: rgb(174, 169, 199255); } [data-entity][data-entity="procedures"]::after { background: rgb(174, 169, 199); }</style>';
}

function getIndicesOf(searchStr, str, type, caseSensitive) {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    var startIndex = 0, index, indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        let endIndex = index + searchStr.length;
        indices.push([index,endIndex,type]);
        startIndex = index + searchStrLen;
    }
    return indices;
}
let prepareResults = async function(resp,tableInput){
try {
    if(resp.status == 'failure'){
        return false;
    }

    tableInput = '<div class="entities">'+tableInput+'</div>';

    let overallIndex = [];

    if(!Object.prototype.hasOwnProperty.call(resp.data, "conditions")) {
        resp.data.conditions = []
    }

    if(!Object.prototype.hasOwnProperty.call(resp.data, "disease")) {
        resp.data.disease = []
    }

    if(!Object.prototype.hasOwnProperty.call(resp.data, "procedures")) {
        resp.data.procedures = []
    }

    resp.data.conditions.forEach(element => {
        let indexes = getIndicesOf(element.extracted_entity,tableInput,'conditions');
        if(indexes.length != 0){
            indexes.forEach(element => {
                overallIndex.push(element);
            });
        }
    });

    resp.data.disease.forEach(element => {
        let indexes = getIndicesOf(element.extracted_entity,tableInput,'disease');
        if(indexes.length != 0){
            indexes.forEach(element => {
                overallIndex.push(element);
            });
        }
    });

    resp.data.procedures.forEach(element => {
        let indexes = getIndicesOf(element.extracted_entity,tableInput,'procedures');
        if(indexes.length != 0){
            indexes.forEach(element => {
                overallIndex.push(element);
            });
        }
    });

    overallIndex = overallIndex.sort(function(a, b) {
        return b[0] - a[0];
      });

    overallIndex.forEach(indice => {
        tableInput = tableInput.slice(0,indice[0]) + '<mark data-entity="'+indice[2]+'">' + tableInput.slice(indice[0],indice[1]) + '</mark>' + tableInput.slice(indice[1]);
    });

    tableInput+= entitiesCSS();

    return tableInput;
} catch (error) {
    console.log(error);
}
}

async function prepareTables(resp){
    try {
        var response_data = resp.data;

        if(!Object.prototype.hasOwnProperty.call(response_data, "conditions")) {
            response_data.conditions = []
        }

        if(!Object.prototype.hasOwnProperty.call(response_data, "disease")) {
            response_data.disease = []
        }

        if(!Object.prototype.hasOwnProperty.call(response_data, "procedures")) {
            response_data.procedures = []
        }
        
        this.clincical_data = response_data["disease"];
        let final_clinical_data = [];
        if(this.clincical_data.length>0){
          for(let i=0;i<this.clincical_data.length;i++)
          {
              let row_index = new Object();
              row_index["extracted_entity"] = this.clincical_data[i]["extracted_entity"];
              row_index["icd_code"] = this.clincical_data[i]["ICD_10_code"];
              row_index["sno_code"] = this.clincical_data[i]["SNOMED_code"];
              row_index["sno_txt"] = this.clincical_data[i]["SNOMED_term"];
              row_index["hierarchy_status"] = this.clincical_data[i]["hierarchy_status"];
              row_index["NHI"] = this.clincical_data[i]["NHI"];
              final_clinical_data.push(row_index);
          }
        }

        // this.final_clinical_data = final_clinical_data;

        let final_conditions_data = [];
        if(response_data["conditions"].length>0){
          for(let i=0;i<response_data["conditions"].length;i++){
              let row_index = new Object();
            //   row_index["icd_code"] = response_data["conditions"][i]["ICD_10_code"];
            //   row_index["sno_code"] = response_data["conditions"][i]["SNOMED_code"];
            //   row_index["sno_txt"] = response_data["conditions"][i]["SNOMED_term"];
              row_index["raw_txt"] = response_data["conditions"][i];
            //   row_index["hierarchy_status"] = response_data["conditions"][i]["hierarchy_status"];
            //   row_index["NHI"] = response_data["conditions"][i]["NHI"];
              final_conditions_data.push(row_index);
          }
        }

        let final_principal_diagnosis_data = [];
        if(response_data["principal_diagnosis"]){
                let row_index = new Object();
                row_index["sno_code"] = response_data["principal_diagnosis"]["SNOMED_code"];
                row_index["icd_code"] = response_data["principal_diagnosis"]["ICD_10_code"];
                row_index["sno_txt"] = response_data["principal_diagnosis"]["SNOMED_term"];
                row_index["raw_txt"] = response_data["principal_diagnosis"]["extracted_entity"];
                row_index["hierarchy_status"] = response_data["principal_diagnosis"]["hierarchy_status"];
                row_index["NHI"] = response_data["principal_diagnosis"]["NHI"];

                final_principal_diagnosis_data.push(row_index);
        }
        // this.final_conditions_data = final_conditions_data;

        let final_procedures_data = [];
        if(response_data["procedures"].length>0){
          for(let i=0;i<response_data["procedures"].length;i++)
          {
            let row_index = new Object();
            row_index["sno_code"] = response_data["procedures"][i]["SNOMED_code"];
            row_index["sno_txt"] = response_data["procedures"][i]["SNOMED_term"];
            // row_index["icd_code"] = response_data["procedures"]["ICD_10_code"];
            row_index["raw_txt"] = response_data["procedures"][i]["extracted_entity"];
            row_index["hierarchy_status"] = response_data["procedures"][i]["hierarchy_status"];
            row_index["NHI"] = response_data["procedures"][i]["NHI"];
            final_procedures_data.push(row_index);
          }
        }

        // this.final_procedures_data = final_procedures_data;

        let principal_diagnosis_data_headers = [{
                                            text: 'Extracted Entity',
                                            align: 'start',
                                            sortable: false,
                                            value: 'raw_txt',
                                        },
                                    { text: 'SNOMED entity', value: 'sno_txt' },
                                    { text: 'SNOMED Code', value: 'sno_code' },
                                    { text: 'ICD-10', value: 'icd_code' },
                                    { text: 'Hierarchy Status', value: 'hierarchy_status'},
                                    { text: 'NHI', value: 'NHI'},
                                    ];

        let procedures_data_headers = [{
                                            text: 'Extracted Entity',
                                            align: 'start',
                                            sortable: false,
                                            value: 'raw_txt',
                                        },
                                    { text: 'SNOMED entity', value: 'sno_txt' },
                                    { text: 'SNOMED Code', value: 'sno_code' },
                                    // { text: 'ICD-10', value: 'icd_code' },
                                    { text: 'Hierarchy Status', value: 'hierarchy_status'},
                                    { text: 'NHI', value: 'NHI'},
                                    ];                                    

        let conditions_data_headers = [{
                                                text: 'Extracted Entity',
                                                align: 'start',
                                                sortable: false,
                                                value: 'raw_txt',
                                            },
                                        { text: 'SNOMED Entity', value: 'sno_txt' },
                                        { text: 'SNOMED Code', value: 'sno_code' },
                                        // { text: 'ICD-10', value: 'icd_code' },
                                        { text: 'Hierarchy Status', value: 'hierarchy_status'},
                                        { text: 'NHI', value: 'NHI'},
                                        ]       
                                        
        let clinical_data_headers = [{
                                            text: 'Extracted Entity',
                                            align: 'start',
                                            sortable: false,
                                            value: 'extracted_entity',
                                            class: 'headerStyle'
                                          },
                                      { text: 'SNOMED Entity', value: 'sno_txt' },
                                      { text: 'SNOMED Code', value: 'sno_code' },
                                      { text: 'ICD-10', value: 'icd_code' },
                                      { text: 'Hierarchy Status', value: 'hierarchy_status'},
                                      { text: 'NHI', value: 'NHI'},
                                      ];                                    

        let finalTableData = {final_clinical_data: final_clinical_data, final_conditions_data:final_conditions_data, final_procedures_data:final_procedures_data,final_principal_diagnosis_data:final_principal_diagnosis_data};
        let finalHeaderData = {clinical_data_headers:clinical_data_headers, conditions_data_headers:conditions_data_headers, procedures_data_headers:procedures_data_headers,principal_diagnosis_data_headers:principal_diagnosis_data_headers};
        let finalReturnData = {finalTableData:finalTableData, finalHeaderData:finalHeaderData};

        return finalReturnData;
    } catch (error) {
        console.log(error);
    }
}

export const helpers = {encryptString,decryptString,encryptJSON,decryptJSON,encryptInteger,decryptedInteger,prepareResults,prepareTables};