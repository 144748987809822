<template>
  <div class="home">
    <XLextraction/>
  </div>
</template>

<script>
import XLextraction from '@/components/XLextraction.vue'

export default {
  name: 'Home',
  components: {
    XLextraction
  },
  created(){
    document.title = "IHX Demo App"
  }
}
</script>
