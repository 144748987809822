<template>
  <v-container fluid>
        <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
                <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
                <v-icon dark large style="padding-right:5%;">
                    mdi-account-circle
                </v-icon>
                <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
                <div class="drp-dwn" v-show="showDropdown">
                    <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
                </div>

                </div>
            </v-col>
            
        </v-row>

        <div style="margin-top:66.3px;width:100%;margin-left:84px;">
            <v-row dense>
                <v-col md="auto" lg="auto">
                    <v-img src="../assets/home_icon.svg" width="21px" style="margin-right:6px;float:left;cursor:pointer;" @click="goBack"></v-img>
                    <span id="home-id" @click="goBack">Home</span> <span id="arrow-id">>></span> 
                </v-col>

                <v-col md="3" lg="3">
                    <v-img src="../assets/folder_icon.svg" width="16px" style="float:left;margin-right:5px;margin-top:0.5%;"></v-img>
                    <span id="header-id">Discharge Summary Digitizer</span>
                </v-col>
            </v-row>
        </div>

        <hr style="border: 0.7px dashed #9F9DE0;left:6.08%;right:8.2%;position:absolute;margin-top:23px;">

        <div style="margin-top:6%;margin-left:117px;" id="new-sub-id">
            New Submission
        </div>
        
        <v-row style="margin-left:130px;margin-top:29px;">
            <v-col md="6" lg="6">
                <v-file-input id="fileInsert"
                                show-size
                                counter        
                                accept="image/*,application/pdf"        
                                label="Attach Document"
                                v-model="fileInput"
                                :disabled="requestSent"
                                dense
                                outlined
                                prepend-inner-icon="mdi-cloud-upload"
                                prepend-icon=""
                                width="546px"
                                height="43px"
                                background-color="rgba(243, 243, 243, 0.43)"
                                style="font-style:italic;font-family: Open Sans;font-color: #A2A2A2;"
                >
                <v-img src="../assets/home_icon.svg"></v-img>
                </v-file-input>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-btn width="158px" height="43px" color="#2E2BEB" @click="submitInput()" :disabled="requestSent" class="white--text" style="font-family: Open Sans;font-weight:bolder;font-size:18px;line-height:26px;">Submit</v-btn>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-progress-circular 
                    v-if="loadingData" id="shownScroll"
                    indeterminate
                    color="#2E2BEB"
                    style="margin-top:-0.5%;"
                >
                </v-progress-circular>
            </v-col>
        </v-row>
      
      <v-row style="margin-top:76px;margin-left:117px;margin-bottom:111px;">
          <v-col md="5" lg="5" v-if="showTable">
              <span id="header-id"></span>
          </v-col>
          <v-col md="6" lg="6" v-if="showTable">
              <span id="header-id" style="margin-left:7%;">OUTPUT</span>
          </v-col>
          <v-col md="5" lg="5" style="margin-left:18px;">
                <iframe name="DP_Log_frame" id="iFrame" :src="imgSource" height="504px" width="100%" v-show="!imageInputHere && showResults"></iframe>
                <img id="inputImage" style="border:2px solid black;" :src="imgSource" height="504px" width="100%" v-show="imageInputHere && showResults">
          </v-col>
          <v-col md="6" lg="6" v-if="showTable" style="margin-left:18px;">
              <v-tabs
                    fixed-tabs
                    color="#2E2BEB"
                    height="50"
                    v-model="activeTab"
                    hide-slider
                >
                    <v-tab style="width:123px;border-left:1px solid #D3D3D3;" :id="activeTab == 0?'table-headers-active':'table-headers-inactive'">
                        Principal Diagnosis
                    </v-tab>
                    <v-tab style="width:123px;" :id="activeTab == 1?'table-headers-active':'table-headers-inactive'">
                        Diseases
                    </v-tab>
                    <v-tab style="width:123px;" :id="activeTab == 2?'table-headers-active':'table-headers-inactive'">
                        Conditions
                    </v-tab>
                    <v-tab style="width:123px;border-right:1px solid #D3D3D3;" :id="activeTab == 3?'table-headers-active':'table-headers-inactive'">
                        Procedures
                    </v-tab>
                </v-tabs>
                <TableView :tableData="currentData[activeTab]" :tableHeaders="currentDataHeaders[activeTab]"/>
          </v-col>
      </v-row>

      <v-footer v-if="showTable" dark padless style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class-show" style="padding-bottom:0%;">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          
          </v-row>
      </v-footer> 
      <v-footer v-if="!showTable" dark padless fixed height="45px" style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          </v-row>
      </v-footer> 
      
      <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar> 
  </v-container>
</template>

<script>
const encryptFunc = require('../js/helpers');
const axios = require('axios').default;
import TableView from '../components/TableView.vue'
import helpers from '../js/apiCalls'

export default {
  name: 'Encoder',
  components: {
      TableView
  },
  created(){
    document.title = "IHX Demo App";
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  mounted () {
        document.addEventListener('click',this.changeState);
          this.interval = setInterval(() => {
            if (this.cirValue >= 100) {
                return (this.cirValue = 0)
            }
            this.cirValue += 20
        }, 1000);
  },
  methods:{
      changeState(e){
            let logoutButtonArea = this.$refs.logoutButton;
            
            if (!logoutButtonArea.contains(e.target)) {
                this.showDropdown = false
            }
      },
      doLogout(){
        this.$store.commit('resetState');
        this.$router.push('/').catch((e)=>{console.log(e);});
      },
      async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
      goBack(){
          this.$router.push('/main');
      },
      async submitInput(){
          this.showTable = false;
          this.imageInputHere = false;
          this.showResults = false;

          if(this.encoderInput == '' && this.fileInput == null){
              this.snackbar = true;
              this.snacktext = "No Input Provided!!";
              return false;
          }
          else if(this.encoderInput != '' && this.fileInput == null){
            this.requestSent = true;
            this.loadingData = true;
            await this.getTxnID();
          }
          else{
              this.requestSent = true;
              this.loadingData = true;

              await this.createTableforImage();
          }
      },
      async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
      },
      async extractImage(){

          if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf")
            return false;

          let url = process.env.VUE_APP_NODE_URL+"/imageExtract";

          let reqObj = {filename : this.fileInput.name};

          let resp = axios.post(url,reqObj);

          return resp;
      },
      async createTableforImage(){
            let formData =  new FormData();
            formData.append("file", this.fileInput);

            let sendFileToUpload = await helpers.saveFileLocally(this.$store.state.userInfo.id,this.fileInput);

            if(!sendFileToUpload){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                var requestAPIinfo = await this.callNodeForID(sendFileToUpload);
                
                if(!requestAPIinfo){
                    this.snackbar = true;
                    this.snacktext = "Failed To extract. Please Try again!!";

                    this.loadingData = false;
                    this.requestSent = false;

                    return;
                }

                if(requestAPIinfo.data.data.summary['num_discharge_summary_pages']>0){
                    let txnID = requestAPIinfo.data.txnId;

                    let tableData = await this.getTableData(txnID);
                    
                    try {
                        if(tableData.data.response.status == 'failure'){
                            this.snackbar = true;
                            this.snacktext = "Failed To extract. Please Try again!!";

                            this.loadingData = false;
                            this.requestSent = false;

                            return;
                        }
                    } catch (error) {
                        this.snackbar = true;
                        this.snacktext = "Failed To extract. Please Try again!!";

                        this.loadingData = false;
                        this.requestSent = false;

                        return;
                    }
                    // let imageExtract = await this.extractImage();
                    let imageExtract = false;
                    
                    let respResults = (imageExtract)?(await encryptFunc.helpers.prepareResults(tableData.data.response,imageExtract.data)):false;
                    let prepareTables = await encryptFunc.helpers.prepareTables(tableData.data.response);

                    await this.setImagePDF(imageExtract,sendFileToUpload);

                    this.final_clinical_data = prepareTables.finalTableData.final_clinical_data;
                    this.final_conditions_data = prepareTables.finalTableData.final_conditions_data;
                    this.final_procedures_data = prepareTables.finalTableData.final_procedures_data;
                    this.final_principal_diagnosis_data = prepareTables.finalTableData.final_principal_diagnosis_data;

                    this.clinical_data_headers = prepareTables.finalHeaderData.clinical_data_headers;
                    this.conditions_data_headers = prepareTables.finalHeaderData.conditions_data_headers;
                    this.procedures_data_headers = prepareTables.finalHeaderData.procedures_data_headers;
                    this.principal_diagnosis_data_headers = prepareTables.finalHeaderData.principal_diagnosis_data_headers;

                    this.encoderResults = respResults;

                    

                    this.showTable = true;
                    this.showResults = true;
                    (imageExtract)?(this.imageFileInput = true):(this.imageFileInput = false);
                    this.requestSent = false;
                    this.loadingData = false;
                }
                else{
                    this.requestSent = false;
                    this.loadingData = false;

                    this.snacktext = 'File does not contain Discharge Summary!!'
                    this.snackbar = true;
                }
            }
      },
      async setImagePDF(imageExtract,sendFileToUpload){
        if(!imageExtract){
            let fileData = await this.getDocx(sendFileToUpload);
            var blob = new Blob([fileData.data], {type: 'application/pdf'});
            var pdfURL = window.URL.createObjectURL(blob);

            if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf"){
                this.imgSource = pdfURL;
                }
            else{
                this.imgSource = pdfURL;
                this.imageInputHere = true;
            }
        }
      },
      async callNodeForID(newFilename){
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': 101,'file':newFilename};
        let idData = await axios.post(url,data);

        if(!idData.data){
            return false;
        }
        return idData;
      },
      async getTxnID(){
          let reqObj = {data: this.encoderInput};

          let url = process.env.VUE_APP_NODE_URL + '/autoEncoderID';

          let resp = await axios.post(url,reqObj);

          if(resp.data){
              let tableData = await this.getTableData(resp.data.txnId);

              let respResults = await encryptFunc.helpers.prepareResults(tableData.data.response,this.encoderInput);
              let prepareTables = await encryptFunc.helpers.prepareTables(tableData.data.response);

              this.final_clinical_data = prepareTables.finalTableData.final_clinical_data;
              this.final_conditions_data = prepareTables.finalTableData.final_conditions_data;
              this.final_procedures_data = prepareTables.finalTableData.final_procedures_data;
              this.final_principal_diagnosis_data = prepareTables.finalTableData.final_principal_diagnosis_data;

              this.clinical_data_headers = prepareTables.finalHeaderData.clinical_data_headers;
              this.conditions_data_headers = prepareTables.finalHeaderData.conditions_data_headers;
              this.procedures_data_headers = prepareTables.finalHeaderData.procedures_data_headers;
              this.principal_diagnosis_data_headers = prepareTables.finalHeaderData.principal_diagnosis_data_headers;
              
              if(!respResults){
                  this.loadingData = false;
                  this.requestSent = false;

                  this.snackbar = true;
                  this.snacktext = "Failed To extract. Please try again!!"

                  return;
              }
            
              this.encoderResults = respResults;

              this.loadingData = false;
              this.requestSent = false;
              this.imageFileInput = true;
              this.showResults = true;
              this.showTable = true;              
          }
      },
      async sleep(ms){
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },
      async getTableData(txnID){
        let totalTries = 15;
        while(totalTries>0){
          this.snackbar = true;
          this.snacktext = 'Waiting for Extraction Results. Please wait!!'
          const url = process.env.VUE_APP_NODE_URL+'/getJSON';
          let data = {'id': txnID};
          var response = await axios.post(url,data);
          
        //   if(!response.data)
        //       return false;
          // if(response.data.response.status=="failure")
          //     return false;
          try {
            if(response.data.response.status=="success")
                return response;
          } catch (error) {
              console.log('Response not generated');
          }
          
          if(totalTries==1)
              return response;
            await this.sleep(15000);
            totalTries-=1;
        }
      },
  },
  data(){
      return {
          showDropdown: false,
          imageInputHere:false,
          snackbar : false,
          snacktext : '',
          encoderInput : '',
          requestSent: false,
          showResults: false,
          encoderResults:'',
          loadingData: false,
          fileInput: null,
          final_clinical_data:[],
          final_conditions_data:[],
          final_procedures_data:[],
          final_principal_diagnosis_data:[],
          clinical_data_headers:[],
          conditions_data_headers:[],
          procedures_data_headers:[],
        //   principal_diagnosis_data_headers:[],
          principal_diagnosis_data_headers:[{
                                            text: 'Extracted Entity',
                                            align: 'start',
                                            sortable: false,
                                            value: 'raw_txt',
                                        },
                                    { text: 'SNOMED entity', value: 'sno_txt' },
                                    { text: 'SNOMED Code', value: 'sno_code' },
                                    { text: 'Hierarchy Status', value: 'hierarchy_status'},
                                    { text: 'NHI', value: 'NHI'},],
          showTable:false,
          imageFileInput:false,
          usernameInfo:this.$store.state.username,
          imgSource:'',
          activeTab:0,
          cirValue:0,
      }
  },
  computed:{
      currentData(){
          return {0 : this.final_principal_diagnosis_data, 1 : this.final_clinical_data, 2: this.final_conditions_data, 3: this.final_procedures_data};
      },
      currentDataHeaders(){
          return {0:this.principal_diagnosis_data_headers,1:this.clinical_data_headers,2:this.conditions_data_headers,3:this.procedures_data_headers};
      },
  },
}
</script>

<style scoped>

#arrow-id{
    vertical-align:top;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    margin-left:16px;
    margin-right:16px;
    color: #4B49C9;
}
#home-id{
    cursor:pointer;
    width: 59px;
    height: 22px;
    left: 111px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: rgba(73, 70, 197, 0.53);
}
#header-id{
    width: 325px;
    height: 22px;
    left: 232px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: #4946C5;
}
#new-sub-id{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #4946C5;
}
#user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}
.foot-class{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
    margin-top:12px;
}
.foot-class-show{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
}
#table-headers-active{
    width: 137px;
    /* left: calc(50% - 137px/2 + 109.5px); */
    /* top: 40.68%; */
    /* bottom: 56.95%; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */

    text-align: center;
    background-color: white;
    color: #2E2BEB;
    border: 1px solid #D3D3D3;
    border-bottom:none;
}
.drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
  }
#table-headers-inactive{
    width: 137px;
    /* left: calc(50% - 137px/2 + 109.5px); */
    /* top: 40.68%; */
    /* bottom: 56.95%; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */
    text-align: center;
    color: rgba(75, 73, 201, 0.61);
    background-color: rgba(228, 228, 247, 0.33);
    border: 1px solid #D3D3D3;
    border-right:none;
    border-left:none;
}
#table-headers-inactive:hover{
    background-color: rgba(228, 228, 247, 0.33) !important;
}
#table-headers-active:hover{
    background-color: transparent !important;
}
</style>
