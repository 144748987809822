<template>
  <v-container>
    <v-row>
      <v-col md="6" lg="6">
        <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%"></iframe>
        <img id="inputImage" width="0%" height="0%" style="border:5px solid black;">
      </v-col>
    <v-col md="6" lg="6">
      <div>
          <TableView tableType="Diseases" :tableData="final_clinical_data" :tableHeaders="clinical_data_headers"/>
          <!-- <TableView tableType="Negations" :tableData="final_negations_data" :tableHeaders="negations_data_headers"/> -->
          <TableView tableType="Conditions" :tableData="final_conditions_data" :tableHeaders="conditions_data_headers"/>
          <TableView tableType="Procedures" :tableData="final_procedures_data" :tableHeaders="procedures_data_headers"/>
          <!-- <TableView tableType="Radiology" :tableData="final_radiology_data" :tableHeaders="radiology_data_headers"/>         -->

      </div>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>


  import TableView from './TableView.vue'
  const axios = require('axios').default;
  const encryptFunc = require('../js/helpers');
  export default {
    name: 'Extracted',
    methods:{
        async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      }
    },
    components:{
        TableView,
    },
    async created(){     
        
        var decrypted_dsdTableJSON = encryptFunc.helpers.decryptJSON(this.$store.state.dsdTableJSON);
        var data_json = decrypted_dsdTableJSON.data;

        // console.log(data_json)

        var response_data = data_json["response"]["data"];
        this.clincical_data = response_data["disease"];
        let final_clinical_data = [];
        if(this.clincical_data.length>0){
          for(let i=0;i<this.clincical_data.length;i++)
          {
              let row_index = new Object();
              row_index["extracted_entity"] = this.clincical_data[i]["extracted_entity"];
              row_index["icd_code"] = this.clincical_data[i]["ICD_10_code"];
              row_index["sno_code"] = this.clincical_data[i]["SNOMED_code"];
              row_index["sno_txt"] = this.clincical_data[i]["SNOMED_term"];
              row_index["hierarchy_status"] = this.clincical_data[i]["hierarchy_status"];
              // row_index["rank"] = this.clincical_data[i]["rank"];
              row_index["NHI"] = this.clincical_data[i]["NHI"];
              // row_index["Position (%)"] = this.clincical_data[i]["Position (%)"];
              // row_index["score"] = this.clincical_data[i]["score"];
              // row_index["context_score"] = this.clincical_data[i]["context_score"];
              final_clinical_data.push(row_index);
          }
        }

        this.final_clinical_data = final_clinical_data;

        let final_conditions_data = [];
        if(response_data["conditions"].length>0){
          for(let i=0;i<response_data["conditions"].length;i++){
              let row_index = new Object();
              row_index["icd_code"] = response_data["conditions"][i]["ICD_10_code"];
              row_index["sno_code"] = response_data["conditions"][i]["SNOMED_code"];
              row_index["sno_txt"] = response_data["conditions"][i]["SNOMED_term"];
              row_index["raw_txt"] = response_data["conditions"][i]["extracted_entity"];
              row_index["hierarchy_status"] = response_data["conditions"][i]["hierarchy_status"];
              row_index["NHI"] = response_data["conditions"][i]["NHI"];
              // row_index["Position (%)"] = response_data["conditions"][i]["Position (%)"];
              // row_index["score"] = response_data["conditions"][i]["score"];
              // row_index["context_score"] = response_data["conditions"][i]["context_score"];
              final_conditions_data.push(row_index);
          }
        }

        this.final_conditions_data = final_conditions_data;

        let final_procedures_data = [];
        if(response_data["procedures"].length>0){
          for(let i=0;i<response_data["procedures"].length;i++)
          {
            let row_index = new Object();
            row_index["sno_code"] = response_data["procedures"][i]["SNOMED_code"];
            row_index["sno_txt"] = response_data["procedures"][i]["SNOMED_term"];
            row_index["raw_txt"] = response_data["procedures"][i]["extracted_entity"];
            row_index["hierarchy_status"] = response_data["procedures"][i]["hierarchy_status"];
            row_index["NHI"] = response_data["procedures"][i]["NHI"];
            // row_index["Position (%)"] = response_data["procedures"][i]["Position (%)"];
            // row_index["score"] = response_data["procedures"][i]["score"];
            // row_index["context_score"] = response_data["procedures"][i]["context_score"];
            final_procedures_data.push(row_index);
          }
        }

        this.final_procedures_data = final_procedures_data;

        // let final_conditions_data = [];
        // if(response_data["conditions"].length>0){
        //   for(let i=0;i<response_data["conditions"].length;i++){
        //     let row_index = new Object();
        //     row_index["icd_code"] = response_data["conditions"][i]["ICD_10_code"];
        //     row_index["sno_code"] = response_data["conditions"][i]["SNOMED_code"];
        //     row_index["sno_txt"] = response_data["conditions"][i]["SNOMED_term"];
        //     row_index["raw_txt"] = response_data["conditions"][i]["extracted_entity"];
        //     row_index["level"] = response_data["conditions"][i]["level"];
        //     row_index["NHI"] = response_data["conditions"][i]["NHI"];
        //     row_index["Position (%)"] = response_data["conditions"][i]["Position (%)"];
        //     row_index["score"] = response_data["conditions"][i]["score"];
        //     row_index["context_score"] = response_data["conditions"][i]["context_score"];
        //     final_conditions_data.push(row_index);
        //   }
        // }     
        // this.final_conditions_data = final_conditions_data;

        // let final_radiology_data = [];
        // if(response_data["radiology"].length>0){
        //   for(let i=0;i<response_data["radiology"].length;i++){
        //     let row_index = new Object();
        //     row_index["sno_code"] = response_data["radiology"][i]["SNOMED_code"];
        //     row_index["sno_txt"] = response_data["radiology"][i]["SNOMED_term"];
        //     row_index["raw_txt"] = response_data["radiology"][i]["extracted_entity"];
        //     row_index["level"] = response_data["radiology"][i]["level"];
        //     row_index["NHI"] = response_data["radiology"][i]["NHI"];
        //     row_index["Position (%)"] = response_data["radiology"][i]["Position (%)"];
        //     row_index["score"] = response_data["radiology"][i]["score"];
        //     row_index["context_score"] = response_data["radiology"][i]["context_score"];
        //     final_radiology_data.push(row_index);
        //   }
        // }

        // this.final_radiology_data = final_radiology_data;

        const decypted_filename = encryptFunc.helpers.decryptString(this.$store.state.inputFileName);

        let fileData = await this.getDocx(decypted_filename); 
        
        var blob = new Blob([fileData.data], {type: 'application/pdf'});
        var pdfURL = window.URL.createObjectURL(blob);
        if(decypted_filename.split(".")[decypted_filename.split(".").length-1].toLowerCase() == "pdf"){
      
          let iFrame = document.getElementById("iFrame");
          iFrame.style.width = '100%';
          iFrame.style.height = '100%';
          iFrame.src = pdfURL;
        }
        else{
          let iFrame = document.getElementById("iFrame");
          iFrame.style.display = 'none';
          var imageOutput = document.getElementById("inputImage");
          imageOutput.style.height = '100%';
          imageOutput.style.width = '100%';
          imageOutput.src = pdfURL;
        }


        // var iFrame = document.getElementById("iFrame");
        // iFrame.src = pdfURL;

    },
    data(){

        return{
            clincical_data: null,
            negations: null,
            principal_disease: null,
            procedures: null,
            fileURL:null,
            final_clinical_data:null,
            final_negations_data:null,
            final_procedures_data:null,
            final_conditions_data:null,  
            final_radiology_data:null,
            clinical_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'extracted_entity',
                      class: 'headerStyle'
                    },
                { text: 'SNOMED Entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'ICD-10', value: 'icd_code' },
                { text: 'Hierarchy Status', value: 'hierarchy_status'},
                // { text: 'Rank', value: 'rank'},
                { text: 'NHI', value: 'NHI'},
                // { text: 'Position (%)', value: 'Position (%)'},
                // { text: 'Score', value: 'score'},
                // { text: 'Context Score', value: 'context_score'},
                ],

            conditions_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED Entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'ICD-10', value: 'icd_code' },
                // { text: 'Level', value: 'level'},
                { text: 'Hierarchy Status', value: 'hierarchy_status'},
                { text: 'NHI', value: 'NHI'},
                // { text: 'Position (%)', value: 'Position (%)'},
                // { text: 'Score', value: 'score'},
                // { text: 'Context Score', value: 'context_score'},
                ],

            negations_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'Level', value: 'level'},
                { text: 'Rank', value: 'rank'},
                { text: 'NHI', value: 'NHI'},
                { text: 'Position (%)', value: 'Position (%)'},
                { text: 'Score', value: 'score'},
                { text: 'Context Score', value: 'context_score'},
                ],

            procedures_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                // { text: 'Level', value: 'level'},
                { text: 'Hierarchy Status', value: 'hierarchy_status'},
                { text: 'NHI', value: 'NHI'},
                // { text: 'Position (%)', value: 'Position (%)'},
                // { text: 'Score', value: 'score'},
                // { text: 'Context Score', value: 'context_score'},
                ],
            radiology_data_headers: [{
                      text: 'Extracted Entity',
                      align: 'start',
                      sortable: false,
                      value: 'raw_txt',
                    },
                { text: 'SNOMED entity', value: 'sno_txt' },
                { text: 'SNOMED Code', value: 'sno_code' },
                { text: 'Level', value: 'level'},
                { text: 'Rank', value: 'rank'},
                { text: 'NHI', value: 'NHI'},
                { text: 'Position (%)', value: 'Position (%)'},
                { text: 'Score', value: 'score'},
                { text: 'Context Score', value: 'context_score'},
            ]    

        }
    },
  }
</script>

<style scoped>

.mx-auto{
    margin-bottom: 5%;
}


</style>