const axios = require('axios').default

const callDocxClassifier = async (fileInputName) => {
    // Call Document Classifier
    try {
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': 101,'file':fileInputName};
        let docxData = await axios.post(url,data);
    
        return docxData.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

const changeFileName = (origFileName,userid,fileInput) => {
    Object.defineProperty(fileInput, 'name', {
        writable: true,
        value: userid+uuidv4().split("-")[0]+origFileName
    });

    return fileInput;
}

const saveFileLocally = async (userid,fileInput) => {
    // To save file locally in backend
    try {
        
    
        let formData =  new FormData();

        let prefix = userid+uuidv4().split("-")[0];

        formData.append("prefix",prefix);
        formData.append("file", fileInput);
        
        const url = process.env.VUE_APP_NODE_URL+"/upload";
        await axios.post(url,formData);

        return prefix+fileInput.name;

    } catch (error) {
        return false;
    }
}

const callDSD = async (docxOutput) => {
    //
    let txnId = docxOutput.txnId;

    if(parseInt(docxOutput.data.summary['num_discharge_summary_pages']) == 0)
        return false;

    let dsdData = await getTableData(txnId);

    return dsdData;
}

async function sleep(ms){
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

async function getTableData(txnID){
    let totalTries = 15;
    while(totalTries>0){
      this.snackbar = true;
      this.snacktext = 'Waiting for Extraction Results. Please wait!!'
      const url = process.env.VUE_APP_NODE_URL+'/getJSON';
      let data = {'id': txnID};
      var response = await axios.post(url,data);
      
      if(!response.data)
          return false;
      // if(response.data.response.status=="failure")
      //     return false;
      if(response.data.response.status=="success")
          return response;
      if(totalTries==1)
          return response;
        await sleep(15000);
        totalTries-=1;
    }
  }

const saveDataStore = () => {
    //
}

const callBillExtractor = () => {
    //
}

function generateResponseObject(elementID,found,identifiersFound){
    let rstObj = new Object();

    rstObj['found'] = found;
    rstObj['id'] = elementID;
    
    identifiersFound.push(rstObj);

    return identifiersFound;
}

const preparedocxClassifierOutput = (reqObj) => {
    //
    let identifiersFound = [];

    let checkedClassifier = [0,1,2,3,4,5,6,7];

    reqObj = reqObj.data;

    checkedClassifier.forEach(elementID => {
        if(elementID == 0){
            if(reqObj.summary['num_bill_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 1){
            if(reqObj.summary['num_discharge_summary_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 2){
            if(reqObj.summary['num_lab_report_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 3){
            if(reqObj.summary['num_radiology_report_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 4){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_pan_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 5){
            if(reqObj.summary['num_pan_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 6){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_aadhar_pan_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
        else if(elementID == 7){
            if(reqObj.summary['num_cheque_pages'] > 0)
                identifiersFound = generateResponseObject(elementID,true,identifiersFound);
            else
                identifiersFound = generateResponseObject(elementID,false,identifiersFound);
        }
  });

  return identifiersFound;
}

let callLabDigitizer = async (filename) => {
    //call Digitizer
    try {
        const url = process.env.VUE_APP_NODE_URL+'/callLabDigitizer';
        let data = {'file':filename};
        let labData = await axios.post(url,data);
        if(labData.data.status == 'failure')
            throw Error('File cannot be extracted!!')

        let lab_report_id = labData.data.txn_id.replace("NP_","");

        return {id : lab_report_id};
    } catch (error) {
        console.log(error);
        return false;
    }
}

let callBillTrigger = (txnid) => {
    try {
        const url = process.env.VUE_APP_NODE_URL+'/callBillTrigger';
        let data = {'txnid':txnid};
        axios.post(url,data);
    } catch (error) {
        console.log(`Error in callBillTrigger Function : ${error}`);
    }
}

let callPriceComp = async (filename) => {
    try {
        const url = process.env.VUE_APP_NODE_URL+'/callPriceComp';
        let data = {'file':filename};
        let priceData = await axios.post(url,data);
        if(!priceData)
            throw Error('File cannot be extracted!!')

        return priceData;

    } catch (error) {
        console.log(`Error in callPriceComp Function : ${error}`);
        return false;
    }
}

module.exports = {callDocxClassifier,saveFileLocally,callDSD,saveDataStore,callPriceComp,callBillExtractor,uuidv4,changeFileName,preparedocxClassifierOutput,callLabDigitizer,callBillTrigger}