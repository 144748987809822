<template>
  <v-container fluid>
        <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
                <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
                <v-icon dark large style="padding-right:5%;">
                    mdi-account-circle
                </v-icon>
                <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
                <div class="drp-dwn" v-show="showDropdown">
                    <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
                </div>

                </div>
            </v-col>
            
        </v-row>

        <div style="margin-top:66.3px;width:100%;margin-left:84px;">
            <v-row dense>
                <v-col md="auto" lg="auto">
                    <v-img src="../assets/home_icon.svg" width="21px" style="margin-right:6px;float:left;cursor:pointer;" @click="goBack"></v-img>
                    <span id="home-id" @click="goBack">Home</span> <span id="arrow-id">>></span> 
                </v-col>

                <v-col md="3" lg="3">
                    <v-img src="../assets/data-analytics.svg" width="16px" style="float:left;margin-right:5px;margin-top:0.5%;"></v-img>
                    <span id="header-id">Business Insights</span>
                </v-col>
            </v-row>
        </div>

        <hr style="border: 0.7px dashed #9F9DE0;left:6.08%;right:8.2%;position:absolute;margin-top:23px;">

        <v-row style="margin-bottom:15%;">
            <v-col md="3" lg="3" v-for="data in dashboard_link" :key="data.id" style="text-align: -moz-center;text-align: -webkit-center;padding:0;">
                <div style="margin-top:6em;margin-left:5em;">
                    <v-progress-circular 
                        v-show="data.iconVar"
                        style="position:absolute;z-index:10000;margin:0;margin-top:8%;margin-left:-1%;" 
                        indeterminate
                        color="black">
                    </v-progress-circular>
                    <v-img :src="data.img_path" width="90%" style="cursor:pointer;" @click="showPdt(data.pos)"></v-img>
                    <div class="text-head" @click="showPdt(data.pos)">{{data.header}}</div>
                </div>
            </v-col>
        </v-row>

        <v-footer dark padless height="45px" style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
            <v-row style="padding:0%;">
                <v-col md="12" lg="12" class="foot-class">
                ©2023, IHX Private Limited. All Rights Reserved.
                </v-col>
            </v-row>
        </v-footer> 
        

        <v-snackbar
            v-model="snackbar"
            >
            {{snacktext}}

            <template v-slot:action="{ attrs }">
                <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
      </v-snackbar> 
  </v-container>
</template>

<script>
const axios = require('axios').default;

export default {
  name: 'Encoder',
  components: {
  },
  created(){
    document.title = "IHX Demo App"
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  mounted(){
    document.addEventListener('click',this.changeState);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  methods:{
    changeState(e){
        let logoutButtonArea = this.$refs.logoutButton;
        
        if (!logoutButtonArea.contains(e.target)) {
            this.showDropdown = false
        }
    },
    showPdt(id){
        if(this.dashboard_link[id]['routing'] != 'none')
        {
            if(this.dashboard_link[id]['routing'] == 'internal'){
                this.$router.push(this.dashboard_link[id]['link']);
            }
            else{
                window.open(this.dashboard_link[id]['link']);
            }
        }
    },
      async showDash(pos){
        if(this.dashboard_link[pos].reportId == undefined){
            this.snacktext = 'Not Available right now!'
            this.snackbar = true;
            return;
        }

        let reqObj = {"reportId":this.dashboard_link[pos].reportId,"groupId":this.dashboard_link[pos].groupId};
        const url = process.env.VUE_APP_NODE_URL+"/getPowerBI";
        try {
            this.dashboard_link[pos].iconVar = true;
            let response = await axios.post(url,reqObj);
            this.dashboard_link[pos].iconVar = false;
            let params = {embedToken:response.data.embedToken,embedUrl:response.data.embedUrl,reportId:response.data.reportId};
            this.$store.commit(this.dashboard_link[pos].storeFunc,params);
            let routeLink = this.$router.resolve({path:`/dashboard/${this.dashboard_link[pos].id}`});
            window.open(routeLink.href,'_blank');
        } catch (error) {
            console.log(`Failed at PowerBI API : ${error}`);
            this.snacktext = 'Not Available right now!'
            this.snackbar = true;
            return;
        }
      },
      goBack(){
          this.$router.push('/main');
      },
      doLogout(){
        this.$store.commit('resetState');
        this.$router.push('/').catch((e)=>{console.log(e);});
      },
  },
  data(){
      return {
          usernameInfo:this.$store.state.username,
          showDropdown: false,
          snackbar:false,
          snacktext:'',
          hosp_cir:false,
          comp_cir:false,
          dashboard_link: [{"id" : 1,
                            "pos" : 0,
                            "header" : "Hospital MetricBoard",
                            "img_path" : require("../assets/hospital_board.png"),
                            "hov" : true,
                            "reportId" : "35ce2bfc-bbfd-446b-99cc-a94d5564bcf3",
                            "groupId" : "9BCDCB05-F2E2-4EE7-BB2D-F4DC7DC6CABF",
                            "storeFunc" : "updateHospitalDashboard",
                            "iconVar" : this.hosp_cir,
                            "routing" : "external",
                            "link" : "https://demo-provider.ihx.in"
                            },
                            {"id" : 2,
                            "pos" : 1,
                            "header" : "Payer Dashboard",
                            "img_path" : require("../assets/comparison_board.png"),
                            "hov" : true,
                            "reportId" : undefined,
                            "groupId" : undefined,
                            "storeFunc" : "updateComparisonDashboard",
                            "iconVar" : this.comp_cir,
                            "routing" : "external",
                            //"link" : "https://drive.google.com/file/d/1zxnP1kDchq7EK2WxND9zCTZVfirSrzm-/view"    
                            "link" : "https://stg-insurer.ihx.in/dashboard"    
                        }]
      }
  },
}
</script>

<style scoped>
.text-head{
    /* position: absolute; */
    width: 226px;
    height: 18px;
    /* left: 103px; */
    /* top: 573px; */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 22px;
    color: #4946C5;
    /* text-align: center; */
/* or 110% */
    margin-top:22px;
    cursor:pointer;
}
#header-id{
    width: 325px;
    height: 22px;
    left: 232px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: #4946C5;
}
#home-id{
    cursor:pointer;
    width: 59px;
    height: 22px;
    left: 111px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: rgba(73, 70, 197, 0.53);
}
#user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}
.drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
}
.foot-class{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
}
#arrow-id{
    vertical-align:top;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    margin-left:16px;
    margin-right:16px;
    color: #4B49C9;
}
</style>
