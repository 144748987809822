<template>
  <div class="home">
    <div style="padding:2%;margin-left:2.5%;">
      <v-row>
        <v-col>
          <v-btn
                  @click="goHome()"
                  color="primary"
          >
            Home
          </v-btn>
        </v-col>
        <v-col>
          <h2 @click="scrollIFrame()">Total Count Left: {{countLeft}}</h2>
        </v-col>
      </v-row>
    </div>
    <v-row style="margin-left:2%;margin-bottom:4%;">
      <v-col md="6" lg="6">
        <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%" scrolling="yes" frameborder="1"></iframe>
        <img id="inputImage" width="0%" height="0%" style="border:5px solid black;">
      </v-col>
      <v-col md="6" lg="6">
        <SimpleTable/>
      </v-col>
    </v-row>
    <v-footer dark padless>
          <v-col  class="py-2 white--text text-center">
            <strong> Number of Pages : {{totalPages}} </strong> 
          </v-col>
          <v-col   class="py-2 white--text text-center">
            <strong> Time Taken : {{totalTimeTaken}} sec </strong> 
          </v-col>  
  </v-footer>
  </div>
</template>

<script>
import SimpleTable from '@/components/SimpleTable.vue'
const axios = require('axios');
const encryptFunc = require('../js/helpers');
import {bus} from '../main'

export default {
  name: 'PageInformation',
  components: {
    SimpleTable
  },
  async created(){

    bus.$on('change-page', function(data){
    console.log(data);

    // const iFrameWindow = document.getElementById('iFrame').contentWindow;
    // const iFrameDocument = document.getElementById('iFrame').contentDocument;
    // console.log(iFrameWindow,iFrameDocument);

    // var frame = window.frames.DP_Log_frame;
    // var doc   = frame.document;
    // var node  = doc.getElementsByTagName('body')[0].lastChild;
    // var y = parseInt(node.offsetTop, 10);
    // console.log(node.offsetTop);
    // console.log(y);
    // frame.scrollTo(0, 100);

    });

    const decypt_classifierCount = encryptFunc.helpers.decryptJSON(this.$store.state.classifierCountLeft);
    this.countLeft = decypt_classifierCount.countDS;

    const decrypt_pagePerData = encryptFunc.helpers.decryptJSON(this.$store.state.pagePerData);
    this.totalPages = decrypt_pagePerData.data.data.summary.total_num_of_pages;

    const decypt_time = encryptFunc.helpers.decryptedInteger(this.$store.state.timeFortxnAPI);
    this.totalTimeTaken = Math.floor(decypt_time);

    document.title = "IHX Demo App"
    const decrypt_file = encryptFunc.helpers.decryptString(this.$store.state.inputFileName);
    let InputfileName = decrypt_file;

    let fileData = await this.getDocx(InputfileName); 
    var blob = new Blob([fileData.data], {type: 'application/pdf'});
    var pdfURL = window.URL.createObjectURL(blob);
    if(InputfileName.split(".")[InputfileName.split(".").length-1].toLowerCase() == "pdf"){
      
      let iFrame = document.getElementById("iFrame");
      iFrame.style.width = '100%';
      iFrame.style.height = '100%';
      iFrame.src = pdfURL;
    }
    else{
      let iFrame = document.getElementById("iFrame");
      iFrame.style.display = 'none';
      var imageOutput = document.getElementById("inputImage");
      imageOutput.style.height = '100%';
      imageOutput.style.width = '100%';
      imageOutput.src = pdfURL;
    }
  },
  methods:{
    async goHome(){
      const decrypt_inputfile = encryptFunc.helpers.decryptString(this.$store.state.inputFileName);
      let inputFile = decrypt_inputfile;
      await this.deleteCurrentFile(inputFile);

      this.$router.push('/submission');
    },
    async deleteCurrentFile(inputFile){
      let reqObj = {file:inputFile};

      const url = process.env.VUE_APP_NODE_URL+"/deleteDocx";
      let response = await axios.post(url,reqObj);

      return response;
    },
    async getDocx(fileName){
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let reqObj = {file:fileName};
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });

          return response;
    }
  },
  data(){
    return {
      totalPages:0,
      totalTimeTaken:0,
      countLeft:0,
    }
  }
}
</script>

<style scoped>
 body{
   margin-bottom:10%;
   padding:2%;
 }
 #div_iframe {
  border-style: inset;
  border-color: grey;
  overflow: scroll;
  height: 100%;
  width: 90%
}
</style>