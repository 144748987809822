<template>
  <div class="home">
      <div style="color:white;padding:1%;background-color:#10b0ad;margin-bottom:4%;">
      <v-row>
        <v-col md="10" lg="10">
            <div style="font-size:2.5em;font-weight: bold;margin-top:1%;">IHX.AI</div>
        </v-col>
        <v-col md="2" lg="2">
            <img src="../assets/ihx-logo.png" alt="">
        </v-col>
      </v-row>
    </div>
    <HelloWorld/>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  created(){
    document.title = "IHX Demo App"
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.home{
  font-family: 'Josefin Sans', sans-serif;
}
</style>
