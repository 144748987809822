import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
const encryptFunc = require('./js/helpers');
Vue.config.productionTip = false

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    count: 0,
    dsdTableJSON : [],
    pickCSVNumber : 0,
    finalTable: [],
    pagePerData: [],
    timeFortxnAPI: 0,
    userInfo:[],
    extractionCountLeft:0,
    classifierCountLeft:0,
    inputFileName:'',
    containsDSPages:0,
    docxJSON: [],
    dsdOutput: [],
    username: '',
  }
}

const store = new Vuex.Store({
  state: {
    count: 0,
    dsdTableJSON : [],
    pickCSVNumber : 0,
    finalTable: [],
    pagePerData: [],
    timeFortxnAPI: 0,
    userInfo:[],
    extractionCountLeft:0,
    classifierCountLeft:0,
    inputFileName:'',
    containsDSPages:0,
    docxJSON: [],
    dsdOutput: [],
    username: '',
    hospitalDashboard: {},
    comparisonDashboard: {},
    dashboardLinks: {1:'hospitalDashboard',2:'hospitalDashboard'}
  },
  mutations: {
    increment (state) {
      state.count++
    },
    updateHospitalDashboard(state,data){
      state.hospitalDashboard = data;
    },
    updateComparisonDashboard(state,data){
      state.comparisonDashboard = data;
    },
    resetState(state){
      Object.assign(state,getDefaultState());
    },
    updateContainsDSPages(state, dsFlag){
      const encryptedText = encryptFunc.helpers.encryptInteger(dsFlag);
      state.containsDSPages = encryptedText;
    },
    updateCSVNumber(state,newID){//integer
      const encryptedText = encryptFunc.helpers.encryptInteger(newID);
      state.pickCSVNumber = encryptedText;
    },
    updateDSDjson(state,DSDTableJson){//json
      const encryptedText = encryptFunc.helpers.encryptJSON(DSDTableJson);
      state.dsdTableJSON = encryptedText;
    },
    updateFinalTable(state,DSDTable){//json
      const encryptedText = encryptFunc.helpers.encryptJSON(DSDTable);
      state.finalTable = encryptedText;
    },
    updatePagePerData(state,pageData){//json
      const encryptedText = encryptFunc.helpers.encryptJSON(pageData);
      state.pagePerData = encryptedText;
    },
    updateTxnAPItime(state,time){//float
      const encryptedText = encryptFunc.helpers.encryptInteger(time);
      state.timeFortxnAPI = encryptedText;
    },
    updateUserInfo(state,userInfo){//json
      // const encryptedText = encryptFunc.helpers.encryptJSON(userInfo);
      state.userInfo = userInfo;
    },
    updateExtractCount(state,extCnt){//json
      const encryptedText = encryptFunc.helpers.encryptJSON(extCnt);
      state.extractionCountLeft = encryptedText;
    },
    updateClassifierCount(state,clsCnt){//json
      const encryptedText = encryptFunc.helpers.encryptJSON(clsCnt);
      state.classifierCountLeft = encryptedText;
    },
    updateInputFilename(state,name){//string
      // const encryptedText = encryptFunc.helpers.encryptString(name);
      state.inputFileName = name;
    },
    updateDocxClassifierOutput(state,docxJSON){
      state.docxJSON = docxJSON;
    },
    updateDSDOutput(state,dsdOutput){
      state.dsdOutput = dsdOutput;
    },
    updateUsername(state,username){
      state.username = username;
    }
  },
    plugins: [createPersistedState({
      storage:window.sessionStorage,
    })]
})

export const bus = new Vue();

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
