<template>
  <v-container fluid class="home">

    <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
                <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
                <v-icon dark large style="padding-right:5%;">
                    mdi-account-circle
                </v-icon>
                <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
                <div class="drp-dwn" v-show="showDropdown">
                    <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
                </div>

                </div>
            </v-col>
            
        </v-row>


        <div style="margin-top:66.3px;width:100%;margin-left:84px;">
            <v-row dense>
                <v-col md="auto" lg="auto">
                    <v-img src="../assets/home_icon.svg" width="21px" style="margin-right:6px;float:left;cursor:pointer;" @click="goBack"></v-img>
                    <span id="home-id" @click="goBack">Home</span> <span id="arrow-id">>></span> 
                </v-col>

                <v-col md="auto" lg="auto">
                    <v-img src="../assets/folder_icon.svg" width="16px" style="float:left;margin-right:5px;margin-top:0.5%;"></v-img>
                    <span id="header-id">PII Anonymization</span>
                </v-col>

                <!-- <v-col  md="auto" lg="auto">
                  <v-btn style="margin-left:3%;margin-left:50%;"  outlined color="pink dark-1" @click="goIndexer()">Indexer</v-btn>
                </v-col> -->
            </v-row>
        </div>

        <hr style="border: 0.7px dashed #9F9DE0;left:6.08%;right:8.2%;position:absolute;margin-top:23px;">

        <div style="margin-top:6%;margin-left:117px;" id="new-sub-id">
            New Submission
        </div>
        
        <v-row style="margin-left:130px;margin-top:29px;">
            <v-col md="6" lg="6">
                <v-file-input id="fileInsert"
                                show-size
                                counter        
                                accept="image/*,application/pdf"        
                                label="Attach Document"
                                v-model="fileInput"
                                :disabled="requestSent"
                                dense
                                outlined
                                prepend-inner-icon="mdi-cloud-upload"
                                prepend-icon=""
                                width="546px"
                                height="43px"
                                background-color="rgba(243, 243, 243, 0.43)"
                                style="font-style:italic;font-family: Open Sans;font-color: #A2A2A2;"
                >
                <v-img src="../assets/home_icon.svg"></v-img>
                </v-file-input>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-btn width="158px" height="43px" color="#2E2BEB" @click="submitInput()" :disabled="requestSent" class="white--text" style="font-family: Open Sans;font-weight:bolder;font-size:18px;line-height:26px;">Submit</v-btn>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-progress-circular 
                    v-if="loadingData" id="shownScroll"
                    indeterminate
                    color="#2E2BEB"
                    style="margin-top:-0.5%;"
                >
                </v-progress-circular>
            </v-col>
        </v-row>

        <v-row style="margin-top:76px;margin-left:117px;margin-bottom:111px;">
          <v-col md="5" lg="5" style="margin-left:18px;">
                <iframe name="DP_Log_frame" id="iFrame" :src="imgSource" height="504px" width="100%" v-show="!imageInputHere && showOutput"></iframe>
                <img id="inputImage" style="border:2px solid black;" :src="imgSource" height="504px" width="100%" v-show="imageInputHere && showOutput">
          </v-col>
          <v-col md="6" lg="6">
            <!-- New Code goes here -->
            <iframe name="DP_Log_frame" id="iFrame_res" :src="imgSource_" height="504px" width="100%" v-show="!imageInputHere && showOutput"></iframe>
            <img id="inputImage_res" style="border:2px solid black;" :src="imgSource_" height="504px" width="100%" v-show="imageInputHere && showOutput">
          </v-col>
          
        </v-row>

      <v-footer v-if="showOutput" dark padless style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class-show" style="padding-bottom:0%;">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          
          </v-row>
      </v-footer> 
      <v-footer v-if="!showOutput" dark padless fixed height="45px" style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          </v-row>
      </v-footer> 

        <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar> 

  </v-container>
</template>

<script>
import axios from 'axios';
import helpers from '../js/apiCalls'

export default {
  name: 'DocClassifier',
  components: {
  },
  created(){
    document.title = "IHX Demo App";
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  mounted(){
    document.addEventListener('click',this.changeState);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  methods:{
    changeState(e){
      let logoutButtonArea = this.$refs.logoutButton;
      
      if (!logoutButtonArea.contains(e.target)) {
        this.showDropdown = false
      }
    },
    selectAllOptions(){
      this.checkedClassifier = this.allChecked;
    },
    goBack(){
      // go back from here
      this.$router.push('/main');
    },
    goIndexer(){
      this.$router.push('/indexer');
    },
    doLogout(){
        this.$store.commit('resetState');
        this.$router.push('/').catch((e)=>{console.log(e);});
      },
    async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
    async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
    },
    async backendPPI (newFileName){
      try {
        const url = process.env.VUE_APP_NODE_URL+"/callPPI";
        let randomVal = await helpers.uuidv4();
        let reqObj = {file: newFileName,randomNum : randomVal};
        let resp = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });

        if(!resp.data)
            return false;

        return resp;
      } catch (error) {
        return false;
      }
    },
    async identifyPage(){
      let formData =  new FormData();
            formData.append("file", this.fileInput);

            let newFilename = await helpers.saveFileLocally(this.$store.state.userInfo.id,this.fileInput);
            if(!newFilename){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                let respFileData = await this.backendPPI(newFilename);

                let respData = {fileData : respFileData, newFilename : newFilename};

                return respData;
            }
    },
    async submitInput(){
      this.imageInputHere = false;
      this.showResults = false;
      
      if(this.fileInput == null){
        this.snackbar = true;
        this.snacktext = 'No Input File Provided';

        return;
      }
      this.showOutput = false;
      this.requestSent = true;
      this.loadingData = true;

      let pageIdentifier = await this.identifyPage();

      let newFileName = pageIdentifier.newFilename;
      let res_fileData = pageIdentifier.fileData;

      if(!res_fileData){
        this.requestSent = false;
        this.loadingData = false;

        this.snackbar = true;
        this.snacktext = 'Service Failed. Please try again.'

        return;
      }


      let fileData = await this.getDocx(newFileName);
      var blob = new Blob([fileData.data], {type: 'application/pdf'});
      var pdfURL = window.URL.createObjectURL(blob);

      if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf"){
      
          this.imgSource = pdfURL;
        }
      else{
          this.imgSource = pdfURL;
          this.imageInputHere = true;
      }
      
      var blob_ = new Blob([res_fileData.data], {type: 'application/pdf'});
      var pdfURL_ = window.URL.createObjectURL(blob_);console.log(pdfURL_);

      if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf"){
      
          this.imgSource_ = pdfURL_;
        }
      else{
          this.imgSource_ = pdfURL_;
          this.imageInputHere = true;
      }
      this.requestSent = false;
      this.loadingData = false;
      this.showOutput = true;
    },
  },
  data(){
    return{
      selectedItem: 0,
      items: [
        { text: 'Bill'},
        { text: 'Lab Report'},
        { text: 'Govt Id'},
        { text: 'Aadhar Card'},
        { text: 'Discharge Summary'}, 
        { text: 'Radiology'},
        { text: 'Pan card'},
        { text: 'Cheque'}
      ],
      usernameInfo :this.$store.state.username,
      showDropdown: false,
      secondRowData:[],
      secondTableHeader:[
            {"label":"Page Category","value":"category"},
            {"label":"Page No.","value":"label"},           
      ],
      imageInputHere:false,
      fileInput:null,
      loadingData:false,
      requestSent:false,
      snackbar:false,
      showOutput: false,
      imgSource:'',
      imgSource_:'',
      snacktext:'',
      outputText:'',
      showRed:false,
      checkedClassifier:[0],
      allChecked:[0,1,2,3,4,5,6,7],
      identifiersFound:[{'id':0,'present':true}],
      categoryDict:{
        0 : 'Bill',
        1 : 'Lab Report',
        2 : 'Govt Id',
        3 : 'Aadhar Card',
        4 : 'Discharge Summary',
        5 : 'Radiology',
        6 : 'PAN Card',
        7 : 'Cheque'
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.home{
  /* font-family: 'Josefin Sans', sans-serif; */
}
.id-head{
  /* width: 76px;
  height: 22px;
  left: 795px;
  top: 778px; */
  margin-left:14%;
  margin-bottom:4%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 22px;
    color: #4946C5;
}
#new-sub-id{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #4946C5;
}
.foot-class{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
    margin-top:12px;
}
.foot-class-show{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
}
#arrow-id{
    vertical-align:top;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    margin-left:16px;
    margin-right:16px;
    color: #4B49C9;
}
.redtextclass{
  height: 26px;
  left: 24.68%;
  right: 12.72%;
  top: calc(50% - 26px/2 - 1px);

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */


  color: #E15554;
}
.greentextclass{
  height: 26px;
  left: 24.68%;
  right: 12.72%;
  top: calc(50% - 26px/2 - 1px);

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  color:#2ABF86;
}
#home-id{
    cursor:pointer;
    width: 59px;
    height: 22px;
    left: 111px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: rgba(73, 70, 197, 0.53);
}
#header-id{
    width: 325px;
    height: 22px;
    left: 232px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: #4946C5;
}

#user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}
.drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
  }
</style>