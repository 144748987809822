<template>
  <v-container fluid class="home">
    <h1 style="color:white;padding:1%;background-color:#10b0ad;">Document Classifier</h1>
    <!-- <v-row>
      
      <v-col md="9" lg="9">
        <div style="padding:2%;">
          <v-btn style="float:left;" outlined color="pink dark-1" @click="goBack()">Home</v-btn>
        </div>
      </v-col>
    </v-row> -->
          <v-row dense>
            <v-col md="6" lg="6">
              <v-card class="mx-auto"
                            max-width="700"
                            outlined style="padding:2%;margin-top:2%;"
                            v-show="showOutput">
                <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%"></iframe>
                <img id="inputImage" width="0%" height="0%" style="border:2px solid black;">
              </v-card>
            </v-col>
            <v-col md="6" lg="6">
              <v-card class="mx-auto"
                            max-width="500"
                            outlined style="padding:2%;margin-top:2%;"
                            v-show="showOutput">
                  <v-card
                    v-for="data in identifiersFound"
                    :key="data.id"
                    :class="!data.found?'red lighten-3':'green lighten-3'"
                    style="margin-bottom:1%;padding:2%;"
                  >
                    {{categoryDict[data.id]}} is {{(data.found)?'present':'not present'}}
                  </v-card>
              </v-card>
            </v-col>
          </v-row>
        <!-- </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import axios from 'axios';
// const encryptFunc = require('../js/helpers');

export default {
  name: 'DocClassifier',
  components: {
  },
  created(){
    document.title = "IHX Demo App"

    this.submitInput(this.$store.state.docxJSON);
  },
  methods:{
    selectAllOptions(){
      this.checkedClassifier = this.allChecked;
    },
    goBack(){
      // go back from here
      this.$router.push('/main');
    },
    async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
    async callNodeForID(){
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': 101,'file':this.fileInput.name};
        let idData = await axios.post(url,data);

        return idData;
    },
    async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
    },
    async identifyPage(){
      let formData =  new FormData();
            formData.append("file", this.fileInput);

            let sendFileToUpload = await this.uploadFile(formData);

            if(sendFileToUpload.status!=200){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                var requestAPIinfo = await this.callNodeForID(this.fileInput.name);

                if(!requestAPIinfo.data){
                  return false;
                }

                return requestAPIinfo;
            }
    },
    generateResponseObject(elementID,found){
      let rstObj = new Object();

      rstObj['found'] = found;
      rstObj['id'] = elementID;
      
      this.identifiersFound.push(rstObj);
    },
    async prepareFinalOutput(reqObj){
      this.identifiersFound = [];

      reqObj = reqObj.data;

      this.checkedClassifier.forEach(elementID => {
          if(elementID == 0){
            if(reqObj.summary['num_bill_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 1){
            if(reqObj.summary['num_discharge_summary_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 2){
            if(reqObj.summary['num_lab_report_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 3){
            if(reqObj.summary['num_radiology_report_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 4){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 5){
            if(reqObj.summary['num_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 6){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_aadhar_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 7){
            if(reqObj.summary['num_cheque_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
      });

      return this.identifiersFound;
    },
    async submitInput(pageIdentifier){
    //   if(this.fileInput == null){
    //     this.snackbar = true;
    //     this.snacktext = 'No Input File Provided';

    //     return;
    //   }
    //   this.showOutput = false;
    //   this.requestSent = true;
    //   this.loadingData = true;

    //   let pageIdentifier = await this.identifyPage();

    //   if(!pageIdentifier){
    //     this.requestSent = false;
    //     this.loadingData = false;

    //     this.snackbar = true;
    //     this.snacktext = 'Service Failed. Please try again.'
    //   }
        console.log(pageIdentifier);
      let prepareOutput = await this.prepareFinalOutput(pageIdentifier);

      prepareOutput.forEach(element => {
          console.log(this.categoryDict[element]);
      });

      prepareOutput = false;
      // let finalTextOutput = 'Input Image '+ (prepareOutput)?'contains ':'does not contain ' + this.categoryDict[this.selectedItem];
      let finalTextOutput = 'Input Image ';
      finalTextOutput+= (prepareOutput)?'contains ':'does not contain ';
      finalTextOutput+= this.categoryDict[this.selectedItem];

      this.outputText = finalTextOutput;

      let fileData = await this.getDocx(this.$store.state.inputFileName);
      var blob = new Blob([fileData.data], {type: 'application/pdf'});
      var pdfURL = window.URL.createObjectURL(blob);

      if(this.$store.state.inputFileName.split(".")[this.$store.state.inputFileName.split(".").length-1].toLowerCase() == "pdf"){
      
          let iFrame = document.getElementById("iFrame");
          iFrame.style.width = '100%';
          iFrame.style.height = '600px';
          iFrame.src = pdfURL;
          document.getElementById("inputImage").style.display = 'none';
        }
      else{
          let iFrame = document.getElementById("iFrame");
          iFrame.style.display = 'none';
          var imageOutput = document.getElementById("inputImage");
          imageOutput.style.height = '100%';
          imageOutput.style.width = '100%';
          imageOutput.src = pdfURL;
      }

      this.showRed = (!prepareOutput)?true:false;
      this.requestSent = false;
      this.loadingData = false;
      this.showOutput = true;
    },
  },
  data(){
    return{
      selectedItem: 0,
      items: [
        { text: 'Bill'},
        { text: 'Discharge Summary'},
        { text: 'Lab Report'},
        { text: 'Radiology'},
        { text: 'Govt Id'},
        { text: 'Pan card'},
        { text: 'Aadhar Card'},
        { text: 'Cheque'}
      ],
      fileInput:null,
      loadingData:false,
      requestSent:false,
      snackbar:false,
      showOutput: false,
      snacktext:'',
      outputText:'',
      showRed:false,
      checkedClassifier:[0,1,2,3,4,5,6,7],
      allChecked:[0,1,2,3,4,5,6,7],
      identifiersFound:[],
      categoryDict:{
        0 : 'Bill',
        1 : 'Discharge Summary',
        2 : 'Lab Report',
        3 : 'Radiology',
        4 : 'Govt Id',
        5 : 'PAN Card',
        6 : 'Aadhar Card',
        7 : 'Cheque'
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.home{
  font-family: 'Josefin Sans', sans-serif;
}
</style>