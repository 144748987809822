<template>
  <v-container fluid class="home">

    <v-row>
            <v-col md="2" lg="2" offset style="margin-top:14px;background-color:rgba(255, 255, 255, 0.958163);text-align:center;border-bottom:solid 1px honeydew">
                <img src="../assets/ihx-logo.png" alt="" width="88" height="35">
            </v-col>
            <v-col md="10" lg="10" style="background: linear-gradient(90deg, rgba(108, 104, 255, 0.958163) 0%, rgba(66, 64, 198, 0.958163) 100%);">
                <div ref="logoutButton" style="padding-top:1.5%;margin-left:89%;cursor:pointer;" @click="showDropdown = !(showDropdown)">
                <v-icon dark large style="padding-right:5%;">
                    mdi-account-circle
                </v-icon>
                <span id="user-active">{{(usernameInfo.length > 6)?usernameInfo.substr(0,1).toUpperCase():usernameInfo.charAt(0).toUpperCase() + usernameInfo.slice(1)}}</span>
                <div class="drp-dwn" v-show="showDropdown">
                    <v-btn color="green" class="ma-2 white--text" type="submit" @click="doLogout">Logout</v-btn>
                </div>

                </div>
            </v-col>
            
        </v-row>


        <div style="margin-top:66.3px;width:100%;margin-left:84px;">
            <v-row dense>
                <v-col md="auto" lg="auto">
                    <v-img src="../assets/home_icon.svg" width="21px" style="margin-right:6px;float:left;cursor:pointer;" @click="goBack"></v-img>
                    <span id="home-id" @click="goBack">Home</span> <span id="arrow-id">>></span> 
                </v-col>

                <v-col md="auto" lg="auto">
                    <v-img src="../assets/folder_icon.svg" width="16px" style="float:left;margin-right:5px;margin-top:0.5%;"></v-img>
                    <span id="header-id">Document Identifier/Indexer</span>
                </v-col>

                <!-- <v-col  md="auto" lg="auto">
                  <v-btn style="margin-left:3%;margin-left:50%;"  outlined color="pink dark-1" @click="goIndexer()">Indexer</v-btn>
                </v-col> -->
            </v-row>
        </div>

        <hr style="border: 0.7px dashed #9F9DE0;left:6.08%;right:8.2%;position:absolute;margin-top:23px;">

        <div style="margin-top:42px;margin-left:117px;" id="new-sub-id">
            <v-row>
              <v-col md="9" lg="9">
                Identification Types
              </v-col>
              
              <v-col>
                <u style="font-size:14px;cursor:pointer;" @click="selectAllOptions()" :disabled="requestSent"> Mark All </u>
              </v-col>
            </v-row>
        </div>

        <v-card width="72.8%" outlined style="margin-left:130px;margin-top:15px;padding:0%;border: 1px solid #B3B2EE;box-sizing: border-box;border-radius: 8px;">
          <v-row noGutters>
            <v-col cols="12" sm="3" md="3" v-for="(item,i) in items" :key="i" style="padding-left:3%;">
              <v-checkbox :label="item.text" :disabled="requestSent" :value="i" v-model="checkedClassifier" color="#2ABF86" :style="(i>3)?'padding:0;height:3vh;margin-bottom:1em;':'height:3vh;padding:0;'"></v-checkbox>
            </v-col>              
          </v-row>
        </v-card>
        <div style="margin-top:6%;margin-left:117px;" id="new-sub-id">
            New Submission
        </div>
        
        <v-row style="margin-left:130px;margin-top:29px;">
            <v-col md="6" lg="6">
                <v-file-input id="fileInsert"
                                show-size
                                counter        
                                accept="image/*,application/pdf"        
                                label="Attach Document"
                                v-model="fileInput"
                                :disabled="requestSent"
                                dense
                                outlined
                                prepend-inner-icon="mdi-cloud-upload"
                                prepend-icon=""
                                width="546px"
                                height="43px"
                                background-color="rgba(243, 243, 243, 0.43)"
                                style="font-style:italic;font-family: Open Sans;font-color: #A2A2A2;"
                >
                <v-img src="../assets/home_icon.svg"></v-img>
                </v-file-input>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-btn width="158px" height="43px" color="#2E2BEB" @click="submitInput()" :disabled="requestSent" class="white--text" style="font-family: Open Sans;font-weight:bolder;font-size:18px;line-height:26px;">Submit</v-btn>
            </v-col>
            <v-col md="auto" lg="auto">
                <v-progress-circular 
                    v-if="loadingData" id="shownScroll"
                    indeterminate
                    color="#2E2BEB"
                    style="margin-top:-0.5%;"
                >
                </v-progress-circular>
            </v-col>
        </v-row>

        <v-row style="margin-top:76px;margin-left:117px;margin-bottom:111px;">
          <v-col md="5" lg="5" style="margin-left:18px;">
                <iframe name="DP_Log_frame" id="iFrame" :src="imgSource" height="504px" width="100%" v-show="!imageInputHere && showOutput"></iframe>
                <img id="inputImage" style="border:2px solid black;" :src="imgSource" height="504px" width="100%" v-show="imageInputHere && showOutput">
          </v-col>
          <v-col md="6" lg="6">
            <v-row>
              <v-col md="12" lg="12" v-show="showOutput">
                <div class="id-head">Identifier</div>
                <v-card class="mx-auto"
                                max-width="400"
                                outlined style="padding:2%;margin-top:1%;border: 1px solid #B3B2EE;box-sizing: border-box;border-radius: 8px;"
                                v-show="showOutput">
                      <div
                        v-for="data in identifiersFound"
                        :key="data.id"
                        :class="!data.found?'redtextclass':'greentextclass'"
                        style="margin-bottom:15px;padding:2%;margin:14px;margin-top:4px;"
                      >
                        <v-row>
                          <v-col md="6" lg="6" style="">
                            <v-icon :style="(data.found)?'color:#2ABF86':'color:#E15554'">{{(data.found)?'mdi-checkbox-marked-circle':'mdi-alert-circle'}}</v-icon> {{(data.found)?'Present':'Not Present'}}
                          </v-col>
                          <v-col style="">
                            <span style="font-size:16px;">{{categoryDict[data.id]}}</span>
                          </v-col>
                        </v-row>
                      </div>
                  </v-card>
                </v-col>
                <v-col md="12" lg="12" v-show="showOutput">
                  <div class="id-head">Indexer</div>
                  <v-card class="mx-auto"
                            max-width="400"
                            outlined style="padding:1%;margin-top:1%;border: 1px solid #B3B2EE;box-sizing: border-box;border-radius: 8px;" v-show="showOutput">
                    <v-simple-table fixed-header class="simple-table-2">
                      <template v-slot:default>
                        <thead class="t-heads">
                            <tr>
                                <th class="text-center" id="t-heads" v-for="header in secondTableHeader" :key="header.value">
                                    {{header.label}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                              v-for="item in secondRowData"
                              :key="item.pageno"
                              class="text-center"
                              @click = "scrollToPage(item.pageno)"
                              >
                              <td>{{ item.category }}</td>
                              <td>{{item.pageno}}</td>
                            </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>
              </v-row>
          </v-col>
          
        </v-row>

      <v-footer v-if="showOutput" dark padless style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class-show" style="padding-bottom:0%;">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          
          </v-row>
      </v-footer> 
      <v-footer v-if="!showOutput" dark padless fixed height="45px" style="display:block;margin:0;bottom:0;width:100%;background:linear-gradient(90deg, #2E2BE2 0%, #5BB4B8 100%);">
          <v-row style="padding:0%;">
            <v-col md="12" lg="12" class="foot-class">
              ©2023, IHX Private Limited. All Rights Reserved.
            </v-col>
          </v-row>
      </v-footer> 

        <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar> 
    <!-- <h1 style="color:white;padding:1%;background-color:#10b0ad;">Document Classifier</h1>
    <v-row>
      <v-col md="3" lg="3">
        <v-card class="mx-left"
        max-width="280"
        tile
        style="padding:1%;border-top:none;"
        outlined
        >
          <v-list dense :disabled="requestSent">
            <v-subheader><h2>Identification Types</h2></v-subheader>

               <div style="float:right;border:solid 2px black;padding:1%;cursor:pointer;" @click="selectAllOptions()">Select all</div><br><br> 
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <img src="https://img.icons8.com/dotty/50/000000/select-all.png" style="float:right;cursor:pointer;" v-bind="attrs" v-on="on" @click="selectAllOptions()"/>
                </template>
                <span>Select All</span>
              </v-tooltip><br><br>

              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                style="height:1em;"
              >
                   <v-list-item-title v-text="item.text" style="font-size:1em;"></v-list-item-title> 
                  <v-checkbox :label="item.text" :value="i" v-model="checkedClassifier" style="padding:0;"></v-checkbox>
              </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col md="9" lg="9">
        <div style="padding:2%;">
          <v-btn style="float:left;" outlined color="pink dark-1" @click="goBack()">Home</v-btn>
          <v-btn style="margin-left:3%;"  outlined color="pink dark-1" @click="goIndexer()">Indexer</v-btn>
        </div>
        <v-card class="mx-auto"
                        max-width="500"
                        outlined style="padding:2%;margin-top:5%;">
                    <h2>New Submission:</h2><br>
                    
                    <v-file-input id="fileInsert"
                        show-size
                        counter        
                        accept="image/*,application/pdf"        
                        label="Attach File"
                        v-model="fileInput"
                        :disabled="requestSent"
                    ></v-file-input><br>
                    <v-btn color="purple dark-4" outlined @click="submitInput()" :disabled="requestSent">Submit</v-btn>
                    <v-progress-circular 
                        v-if="loadingData" id="shownScroll"
                        indeterminate
                        color="black">
                    </v-progress-circular>
          </v-card>
          <v-row>
            <v-col md="6" lg="6">
              <v-card class="mx-auto"
                            max-width="500"
                            outlined style="padding:2%;margin-top:2%;"
                            v-show="showOutput">
                <iframe name="DP_Log_frame" id="iFrame" width="0%" height="0%"></iframe>
                <img id="inputImage" width="0%" height="0%" style="border:2px solid black;">
              </v-card>
            </v-col>
            <v-col md="6" lg="6">
              <v-card class="mx-auto"
                            max-width="500"
                            outlined style="padding:2%;margin-top:2%;"
                            v-show="showOutput">
                  <v-card
                    v-for="data in identifiersFound"
                    :key="data.id"
                    :class="!data.found?'red lighten-3':'green lighten-3'"
                    style="margin-bottom:1%;padding:2%;"
                  >
                    {{categoryDict[data.id]}} is {{(data.found)?'present':'not present'}}
                  </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
    
    <v-snackbar
        v-model="snackbar"
        >
        {{snacktext}}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
      </v-snackbar> -->
  </v-container>
</template>

<script>
import axios from 'axios';
// const encryptFunc = require('../js/helpers');
import helpers from '../js/apiCalls'

export default {
  name: 'DocClassifier',
  components: {
  },
  created(){
    document.title = "IHX Demo App";
    if(this.$store.state.userInfo.length == 0)
        this.$router.push('/');
  },
  mounted(){
    document.addEventListener('click',this.changeState);
  },
  beforeDestroy () {
    document.removeEventListener('click',this.changeState)
  },
  methods:{
    changeState(e){
      let logoutButtonArea = this.$refs.logoutButton;
      
      if (!logoutButtonArea.contains(e.target)) {
        this.showDropdown = false
      }
    },
    selectAllOptions(){
      this.checkedClassifier = this.allChecked;
    },
    goBack(){
      // go back from here
      this.$router.push('/main');
    },
    goIndexer(){
      this.$router.push('/indexer');
    },
    doLogout(){
        this.$store.commit('resetState');
        this.$router.push('/').catch((e)=>{console.log(e);});
      },
    async getDocx (inputFileName) {
          let reqObj = {file:inputFileName};
          const url = process.env.VUE_APP_NODE_URL+"/getDocx";
          let response = await axios.post(url,reqObj,{
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/pdf',
            }
          });
          return response;
      },
    async callNodeForID(newFileName){
        const url = process.env.VUE_APP_NODE_URL+'/sendRequest';
        let data = {'id': 101,'file':newFileName};
        let idData = await axios.post(url,data);

        return idData;
    },
    async uploadFile (formData) {
          const url = process.env.VUE_APP_NODE_URL+"/upload";
          let response = await axios.post(url,formData);
          return response;
    },
    async identifyPage(){
      let formData =  new FormData();
            formData.append("file", this.fileInput);

            let newFilename = await helpers.saveFileLocally(this.$store.state.userInfo.id,this.fileInput);

            if(!newFilename){
                this.requestSent = false;
                this.loadingData = false;
                
                return;
            }
            else{
                this.snacktext = "File sent for Processing";
                this.snackbar = true;

                var requestAPIinfo = await this.callNodeForID(newFilename);

                if(!requestAPIinfo.data){
                  return false;
                }

                let returnObj = {requestAPIinfo : requestAPIinfo, newFilename: newFilename};

                return returnObj;
            }
    },
    generateResponseObject(elementID,found){
      let rstObj = new Object();

      rstObj['found'] = found;
      rstObj['id'] = elementID;
      
      this.identifiersFound.push(rstObj);
    },
    async prepareFinalOutput(reqObj){
      this.identifiersFound = [];
      let txnid = reqObj.txnId;
      reqObj = reqObj.data;

      this.checkedClassifier.forEach(elementID => {
          if(elementID == 0){
            if(reqObj.summary['num_bill_pages'] > 0){
              this.generateResponseObject(elementID,true);
              helpers.callBillTrigger(txnid);
            }
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 4){
            if(reqObj.summary['num_discharge_summary_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 1){
            if(reqObj.summary['num_lab_report_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 5){
            if(reqObj.summary['num_radiology_report_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 3){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_pan_pages'] > 0 || reqObj.summary['num_aadhar_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 6){
            if(reqObj.summary['num_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 3){
            if(reqObj.summary['num_aadhar_pages'] > 0 || reqObj.summary['num_aadhar_pan_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
          else if(elementID == 7){
            if(reqObj.summary['num_cheque_pages'] > 0)
              this.generateResponseObject(elementID,true);
            else
              this.generateResponseObject(elementID,false);
          }
      });

      return this.identifiersFound;
    },
    async prepareTableOutput(respObj){
      let finalData = respObj.data.data;

      let keyHere = Object.keys(finalData.details)[0];    
      let secondTableData = finalData.details[`${keyHere}`];

      let pageNumberMap = new Object();
      if(secondTableData.bill_pages)
        secondTableData.bill_pages.forEach(element => {
            pageNumberMap[element] = "Bill Page";
        });

      if(secondTableData.discharge_summary_pages)
        secondTableData.discharge_summary_pages.forEach(element => {
            pageNumberMap[element] = "Discharge Summary Page";
        });

      if(secondTableData.nhi_pages)
        secondTableData.nhi_pages.forEach(element => {
            pageNumberMap[element] = "NHI Page";
        });

      if(secondTableData.others)
        secondTableData.others.forEach(element => {
            pageNumberMap[element] = "Others Page";
        });

      if(secondTableData.radiology_report_pages)
        secondTableData.radiology_report_pages.forEach(element => {
            pageNumberMap[element] = "Radiology Page";
        });

      if(secondTableData.lab_report_pages)
        secondTableData.lab_report_pages.forEach(element => {
            pageNumberMap[element] = "Lab Report Page";
        });
      
      if(secondTableData.kyc_pages)
        secondTableData.kyc_pages.forEach(element => {
            pageNumberMap[element] = "KYC Page";
        });

      if(secondTableData.pan_pages)
        secondTableData.pan_pages.forEach(element => {
            pageNumberMap[element] = "PAN Page";
        });

      if(secondTableData.aadhar_pages)
        secondTableData.aadhar_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar Page";
        });

      if(secondTableData.aadhar_pan_pages)
        secondTableData.aadhar_pan_pages.forEach(element => {
            pageNumberMap[element] = "Aadhar PAN Page";
        });

      if(secondTableData.claim_form_pages)
        secondTableData.claim_form_pages.forEach(element => {
            pageNumberMap[element] = "Claim Form Page";
        });

      if(secondTableData.declaration_form_pages)
        secondTableData.declaration_form_pages.forEach(element => {
            pageNumberMap[element] = "Declaration Form Page";
        });

      if(secondTableData.cheque_pages)
        secondTableData.cheque_pages.forEach(element => {
            pageNumberMap[element] = "Cheque Page";
        });

      this.secondRowData = [];

      for (var key in pageNumberMap) {
          let rowIndex = new Object();
          rowIndex["pageno"] = key;
          rowIndex["category"] = pageNumberMap[key];

          this.secondRowData.push(rowIndex);
      }
    },
    async submitInput(){
      this.imageInputHere = false;
      this.showResults = false;
      
      if(this.fileInput == null){
        this.snackbar = true;
        this.snacktext = 'No Input File Provided';

        return;
      }
      this.showOutput = false;
      this.requestSent = true;
      this.loadingData = true;

      let pageIdentifier = await this.identifyPage();

      let newFileName = pageIdentifier.newFilename;
      pageIdentifier = pageIdentifier.requestAPIinfo;

      if(!pageIdentifier){
        this.requestSent = false;
        this.loadingData = false;

        this.snackbar = true;
        this.snacktext = 'Service Failed. Please try again.'
      }
      let prepareOutput = await this.prepareFinalOutput(pageIdentifier.data);
      await this.prepareTableOutput(pageIdentifier);

      prepareOutput = false;
      // let finalTextOutput = 'Input Image '+ (prepareOutput)?'contains ':'does not contain ' + this.categoryDict[this.selectedItem];
      let finalTextOutput = 'Input Image ';
      finalTextOutput+= (prepareOutput)?'contains ':'does not contain ';
      finalTextOutput+= this.categoryDict[this.selectedItem];

      this.outputText = finalTextOutput;

      let fileData = await this.getDocx(newFileName);
      var blob = new Blob([fileData.data], {type: 'application/pdf'});
      var pdfURL = window.URL.createObjectURL(blob);

      if(this.fileInput.name.split(".")[this.fileInput.name.split(".").length-1].toLowerCase() == "pdf"){
      
          this.imgSource = pdfURL;
        }
      else{
          this.imgSource = pdfURL;
          this.imageInputHere = true;
      }

      this.showRed = (!prepareOutput)?true:false;
      this.requestSent = false;
      this.loadingData = false;
      this.showOutput = true;
    },
  },
  data(){
    return{
      selectedItem: 0,
      items: [
        { text: 'Bill'},
        { text: 'Lab Report'},
        { text: 'Govt Id'},
        { text: 'Aadhar Card'},
        { text: 'Discharge Summary'}, 
        { text: 'Radiology'},
        { text: 'Pan card'},
        { text: 'Cheque'}
      ],
      usernameInfo :this.$store.state.username,
      showDropdown: false,
      secondRowData:[],
      secondTableHeader:[
            {"label":"Page Category","value":"category"},
            {"label":"Page No.","value":"label"},           
      ],
      imageInputHere:false,
      fileInput:null,
      loadingData:false,
      requestSent:false,
      snackbar:false,
      showOutput: false,
      imgSource:'',
      snacktext:'',
      outputText:'',
      showRed:false,
      checkedClassifier:[0],
      allChecked:[0,1,2,3,4,5,6,7],
      identifiersFound:[{'id':0,'present':true}],
      categoryDict:{
        0 : 'Bill',
        1 : 'Lab Report',
        2 : 'Govt Id',
        3 : 'Aadhar Card',
        4 : 'Discharge Summary',
        5 : 'Radiology',
        6 : 'PAN Card',
        7 : 'Cheque'
      }
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;500&display=swap');

.home{
  /* font-family: 'Josefin Sans', sans-serif; */
}
.id-head{
  /* width: 76px;
  height: 22px;
  left: 795px;
  top: 778px; */
  margin-left:14%;
  margin-bottom:4%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  line-height: 22px;
    color: #4946C5;
}
#new-sub-id{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #4946C5;
}
.foot-class{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
    margin-top:12px;
}
.foot-class-show{
    padding-bottom:0;
    /* padding:1%; */
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 22px; */
    margin-left:108px;
}
#arrow-id{
    vertical-align:top;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    align-items: center;
    text-transform: uppercase;
    margin-left:16px;
    margin-right:16px;
    color: #4B49C9;
}
.redtextclass{
  height: 26px;
  left: 24.68%;
  right: 12.72%;
  top: calc(50% - 26px/2 - 1px);

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */


  color: #E15554;
}
.greentextclass{
  height: 26px;
  left: 24.68%;
  right: 12.72%;
  top: calc(50% - 26px/2 - 1px);

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  color:#2ABF86;
}
#home-id{
    cursor:pointer;
    width: 59px;
    height: 22px;
    left: 111px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: rgba(73, 70, 197, 0.53);
}
#header-id{
    width: 325px;
    height: 22px;
    left: 232px;
    top: 130px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 110% */

    /* text-transform: uppercase; */

    color: #4946C5;
}

#user-active{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
}
.drp-dwn{
    z-index: 10;
    position: absolute;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    background: #fff;
    border-radius:4%;
  }
</style>